const Privacy = () => {
  return (
    <div className="legal-container">
      <div className="legal-heading">
        <h1 className="legal-title">Política de Privacidade - Arkspace</h1>
        <span className="legal-version">Versão de: outubro de 2023</span>
      </div>

      <section>
        <h2>l. Introdução</h2>

        <p>ARKSPACE, pessoa jurídica de direito privado, leva a sua privacidade a sério e zela pela segurança e proteção de dados de todos os seus clientes, parceiros, fornecedores e usuários do site de domínio “arkspace.ai” e qualquer outro site, loja ou aplicativo operado pela empresa.</p>
        <p>Esta Política de Privacidade, destina-se a informá-lo sobre o modo como nós utilizamos e divulgamos informações coletadas em suas visitas à nossa plataforma e em mensagens que trocamos com você.</p>
        <p>Esta Política de Privacidade aplica-se somente a informações coletadas por meio do site.</p>
        <p>AO ACESSAR O SITE, ENVIAR COMUNICAÇÕES OU FORNECER QUALQUER TIPO DE DADO PESSOAL, VOCÊ DECLARA ESTAR CIENTE COM RELAÇÃO AOS TERMOS AQUI PREVISTOS E DE ACORDO COM A POLÍTICA DE PRIVACIDADE, A QUAL DESCREVE AS FINALIDADES E FORMAS DE TRATAMENTO DE SEUS DADOS PESSOAIS QUE VOCÊ DISPONIBILIZAR NO SITE.</p>
        <p>Esta Política de Privacidade fornece uma visão geral de nossas práticas de privacidade e das escolhas que você pode fazer, bem como direitos que você pode exercer em relação aos Dados Pessoais tratados por nós. Se você tiver alguma dúvida sobre o uso de Dados Pessoais, entre em contato com contato@arkspace.ai.</p>
        <p>Além disso, a Política de Privacidade não se aplica a quaisquer aplicativos, produtos, serviços, site ou recursos de mídia social de terceiros que possam ser oferecidos ou acessados por meio do site. O acesso a esses links fará com que você deixe o nosso site e poderá resultar na coleta ou compartilhamento de informações sobre você por terceiros. Nós não controlamos, endossamos ou fazemos quaisquer representações sobre sites de terceiros ou suas práticas de privacidade, que podem ser diferentes das nossas. Recomendamos que você revise a política de privacidade de qualquer site com o qual você interaja antes de permitir a coleta e o uso de seus Dados Pessoais.</p>
        <p>Caso você nos envie Dados Pessoais referentes a outras pessoas físicas, você declara ter a competência para fazê-lo e declara ter obtido o consentimento necessário para autorizar o uso de tais informações nos termos desta Política de Privacidade.</p>
        <p>Fazemos referência à empresa através do uso de termos tais como “site”, “nós”, “nosso”, “conosco”, palavras tais como “você”, “seu”, e expressões similares referem-se a nossos clientes, ou a usuários de nosso site. Ao visitar e usufruir do site, você automaticamente aceita as condições descritas a seguir, que compõem a Política de Privacidade.</p>
        <p>Ao concordar com nossa política de cookies na primeira vez que entrar em nosso site, tal expressa concordância se fará válida pelas demais que nosso site for acessado.</p>
        <p>Sua privacidade é importante para nós, portanto, sendo você um usuário, por favor, reserve um tempo para conhecer nossas práticas.</p>
      </section>

      <section>
        <h2>ll. Coleta de dados pessoais</h2>

        <p>Aqui traremos o tipo de dados pessoais que poderão ser coletados, armazenados e utilizados pela nossa empresa, os quais poderemos utilizar para os fins estabelecidos no item III desta Política de Privacidade:</p>
        <div className="topics-group">
          <p className="topic">a. &nbsp; informações sobre o seu computador, incluindo seu endereço IP, localização geográfica, tipo e versão do navegador e sistema operacional;</p>
          <p className="topic">b. &nbsp; informações sobre suas visitas e uso deste site, incluindo fonte de referência, duração da visita, visualizações de página e caminhos de navegação no site;</p>
          <p className="topic">c. &nbsp; informações, que você digita quando se registra em nosso site, como, por exemplo, seu endereço de e-mail;</p>
          <p className="topic">d. &nbsp; informações que você digita ao criar um perfil em nosso site – por exemplo, seu nome, fotos de perfil, sexo, data de nascimento, CPF ou CNPJ;</p>
          <p className="topic">e. &nbsp; informações, como seu nome e endereço de e-mail, que você digita para configurar assinaturas de nossos e-mails e/ou newsletters;</p>
          <p className="topic">f. &nbsp; informações que você digita durante o uso dos serviços em nosso site;</p>
          <p className="topic">g. &nbsp; informações geradas ao usar nosso site, incluindo quando, com que frequência e em que circunstâncias você o utiliza;</p>
          <p className="topic">h. &nbsp; informações que você publica em nosso site com a intenção de publicá-las na internet, incluindo seu nome de usuário, fotos de perfil e o conteúdo de suas publicações;</p>
          <p className="topic">i. &nbsp; informações contidas em quaisquer comunicações que você nos envia por e-mail ou através de nosso site, incluindo o conteúdo e os metadados da comunicação;</p>
          <p className="topic">j. &nbsp; qualquer outra informação pessoal que você nos enviar.</p>
        </div>
        <p>Solicitamos que não sejam divulgadas em nosso site informações pessoais sobre outras pessoas, a não ser que você possua a expressa concordância deste terceiro para autorizar tal ato, em concordância com a presente Política de Privacidade.</p>
      </section>

      <section>
        <h2>lll. Uso de dados pessoais</h2>

        <p>Os dados pessoais coletados em nosso site poderão ser utilizados para diversos fins, como para o auxílio do normal funcionamento de nossa empresa e para prestar um serviço cada vez melhor e personalizado para nossos usuários. Segue abaixo os motivos pelos quais utilizaremos os dados pessoais de nossos usuários:</p>
        <div className="topics-group">
          <p className="topic">a. &nbsp; administrar nosso site e nossos negócios;</p>
          <p className="topic">b. &nbsp; personalizar nosso site para você;</p>
          <p className="topic">c. &nbsp; possibilitar o uso dos serviços disponíveis em nosso site;</p>
          <p className="topic">d. &nbsp; enviar produtos adquiridos através do nosso site;</p>
          <p className="topic">e. &nbsp; prestar serviços adquiridos através do nosso site;</p>
          <p className="topic">f. &nbsp; enviar extratos, faturas e lembretes de pagamento, bem como coletar seus pagamentos;</p>
          <p className="topic">g. &nbsp; enviar comunicações comerciais que não sejam de marketing;</p>
          <p className="topic">h. &nbsp; enviar notificações por e-mail solicitadas especificamente por você;</p>
          <p className="topic">i. &nbsp; enviar nossa newsletter por e-mail, caso você a tenha solicitado (você pode nos informar a qualquer momento se não quiser mais receber a newsletter);</p>
          <p className="topic">j. &nbsp; enviar comunicações de marketing relacionadas aos nossos negócios ou aos negócios de terceiros cuidadosamente selecionados que acreditamos ser do seu interesse, por correio ou, onde você especificamente concordou com isso, por e-mail ou tecnologia semelhante (você pode nos informar a qualquer momento se não mais quiser mais receber comunicações de marketing);</p>
          <p className="topic">k. &nbsp; fornecer a terceiros informações estatísticas sobre nossos usuários (mas esses terceiros não poderão identificar nenhum usuário individual a partir dessas informações);</p>
          <p className="topic">l. &nbsp; lidar com perguntas e reclamações feitas por você ou sobre você em relação ao nosso site;</p>
          <p className="topic">m. &nbsp; manter nosso site seguro e evitar fraudes;</p>
          <p className="topic">n. &nbsp; verificar a conformidade com os termos e condições que regem o uso do nosso site (incluindo o monitoramento de mensagens privadas enviadas por meio do serviço de mensagens privadas do nosso site); e</p>
          <p className="topic">o. &nbsp; outros usos.</p>
        </div>
        <p>Se você enviar informações pessoais para publicação em nosso site, publicaremos e usaremos essas informações de acordo com a licença que você nos concedeu.</p>
        <p>Suas configurações de privacidade podem ser usadas para limitar a publicação de suas informações em nosso site e ajustadas através do uso de controles de privacidade no site.</p>
        <p>Sem seu consentimento expresso, não forneceremos suas informações pessoais a terceiros para fins de marketing direto por parte deles ou de terceiros.</p>
      </section>

      <section>
        <h2>lV. Divulgação de informações pessoais</h2>

        <p>Podemos divulgar suas informações pessoais a qualquer um de nossos funcionários, executivos, seguradoras, consultores profissionais, agentes, fornecedores ou subcontratados conforme razoavelmente necessário para os fins estabelecidos nesta política.</p>
        <p>Podemos divulgar suas informações pessoais a qualquer membro de nosso grupo de empresas (isso significa nossas subsidiárias, nossa holding e todas as suas subsidiárias) conforme razoavelmente necessário para os fins estabelecidos nesta política, em estrito cumprimento das imposições da Lei Geral de Proteção de Dados Pessoais (LGPD).</p>
        <p>Podemos divulgar suas informações com terceiros, caso ocorra qualquer reorganização, fusão, venda, joint venture, cessão, transmissão ou transferência de toda ou parte da nossa empresa, ativo ou capital (incluindo os relativos à falência ou processos semelhantes).</p>

        <p>Podemos divulgar suas informações pessoais também:</p>
        <div className="topics-group">
          <p className="topic">a. &nbsp; na medida em que somos obrigados a fazê-lo por lei;</p>
          <p className="topic">b. &nbsp; em relação a qualquer processo judicial em andamento ou potencial;</p>
          <p className="topic">c. &nbsp; para estabelecer, exercer ou defender nossos direitos legais (incluindo fornecer informações a terceiros para fins de prevenção de fraudes e redução do risco de crédito);</p>
          <p className="topic">d. &nbsp; ao comprador (ou comprador em potencial) de qualquer negócio ou ativo que estejamos vendendo (ou contemplando vender); e</p>
          <p className="topic">e. &nbsp; a qualquer pessoa que acreditemos razoavelmente que possa solicitar a um tribunal ou outra autoridade competente a divulgação dessas informações pessoais, quando, em nossa opinião razoável, for provável que tal tribunal ou autoridade ordene a divulgação dessas informações pessoais. Nesta hipótese, tratando-se de dado pessoal sensível, será realizada a comunicação justificando o embasamento para essa divulgação. Exceto conforme estabelecido nesta política, não forneceremos suas informações pessoais a terceiros.</p>
        </div>
      </section>

      <section>
        <h2>V. Transferências internacionais de dados</h2>

        <div className="topics-group">
          <p className="topic">1. &nbsp; As informações que coletamos podem ser armazenadas, processadas e transferidas entre qualquer um dos países em que operamos, seja atualmente ou no futuro, assim como para qualquer um dos agentes e empresas descritos no item IV, a fim de nos permitir usar as informações de acordo com esta política.</p>
          <p className="topic">2. &nbsp; As informações que coletamos podem ser transferidas para os seguintes países que não possuem leis de proteção de dados equivalentes às vigentes no Espaço Econômico Europeu: Estados Unidos da América, Rússia, Japão, China e Índia.</p>
          <p className="topic">3. &nbsp; As informações pessoais que você publica em nosso site ou envia para publicação em nosso site podem estar disponíveis, através da internet, em todo o mundo. Não podemos impedir o uso ou uso indevido de tais informações por terceiros.</p>
          <p className="topic">4. &nbsp; Você concorda expressamente com as transferências de informações pessoais descritas nesta seção V.</p>
        </div>
      </section>

      <section>
        <h2>Vl. Retenção de informações pessoais</h2>

        <div className="topics-group">
          <p className="topic">1. &nbsp; Esta seção VI define nossas políticas e procedimentos de retenção de dados, projetados para ajudar a garantir o cumprimento de nossas obrigações legais em relação à retenção e exclusão de informações pessoais.</p>
          <p className="topic">2. &nbsp; As informações pessoais que processamos para qualquer propósito ou propósitos não devem ser mantidas por mais tempo do que o necessário para esse propósito ou propósitos.</p>
          <p className="topic">3. &nbsp; Não obstante as outras disposições desta seção VI, reteremos documentos (incluindo documentos eletrônicos) que contenham dados pessoais:</p>
          <p className="topic">&bull; &nbsp; na medida em que somos obrigados a fazê-lo por lei;</p>
          <p className="topic">&bull; &nbsp; se acreditarmos que os documentos podem ser relevantes para qualquer processo judicial em andamento ou potencial; e</p>
          <p className="topic">&bull; &nbsp; para estabelecer, exercer ou defender nossos direitos legais (incluindo fornecer informações a terceiros para fins de prevenção de fraudes e redução do risco de crédito).</p>
          <p className="topic">&bull; &nbsp; Não ocorrendo as hipóteses destacadas anteriormente, os dados serão eliminados com o fim da utilização dos nossos serviços ou a pedido expresso do titular</p>
        </div>
      </section>

      <section>
        <h2>Vll. Segurança dos Dados Pessoais</h2>

        <p>Buscamos adotar as medidas técnicas e organizacionais previstas pelas Leis de Proteção de Dados adequadas para proteção dos Dados Pessoais na nossa organização. Infelizmente, nenhuma transmissão ou sistema de armazenamento de dados tem a garantia de serem 100% seguros. Caso tenha motivos para acreditar que sua interação conosco tenha deixado de ser segura (por exemplo, caso acredite que a segurança de qualquer uma de suas contas foi comprometida), favor nos notificar imediatamente.</p>
        <p>Importante ressaltar que você, usuário, é responsável por manter em sigilo a senha para acessar o nosso site, e informamos que jamais iremos solicitar sua senha (exceto quando fizer login em nosso site). Caso qualquer número, e-mail ou qualquer pessoa em qualquer situação solicite sua senha dizendo que faz parte de nossa empresa, NÃO FORNEÇA. Não nos responsabilizaremos pelo fornecimento de senhas a terceiros, mesmo que estejam se passando por um funcionário de nossa empresa.</p>
      </section>

      <section>
        <h2>Vlll. Atualizações desta Política de Privacidade</h2>

        <p>Se modificarmos nossa Política de Privacidade, publicaremos o novo texto no site, com a data de revisão atualizada. Podemos alterar esta Política de Privacidade a qualquer momento. Caso haja alteração significativa nos termos desta Política de Privacidade, podemos informá-lo por meio das informações de contato que tivermos em nosso banco de dados ou por meio de notificação em nosso site.</p>
        <p>Recordamos que nós temos como compromisso não tratar os seus Dados Pessoais de forma incompatível com os objetivos descritos acima, exceto se de outra forma requerido por lei ou ordem judicial.</p>
        <p>Sua utilização do site após as alterações significa que aceitou as Políticas de Privacidade revisadas.</p>
      </section>

      <section>
        <h2>lX. Direitos do Usuário</h2>

        <p>Você pode, a qualquer momento, requerer: (i) confirmação de que seus Dados Pessoais estão sendo tratados; (ii) acesso aos seus Dados Pessoais; (iii) correções a dados incompletos, inexatos ou desatualizados; (iv) anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto em lei; (v) portabilidade de Dados Pessoais a outro prestador de serviços, contanto que isso não afete nossos segredos industriais e comerciais; (vi) eliminação de Dados Pessoais tratados com seu consentimento, na medida do permitido em lei; (vii) informações sobre as entidades às quais seus Dados Pessoais tenham sido compartilhados; (viii) informações sobre a possibilidade de não fornecer o consentimento e sobre as consequências da negativa; e (ix) revogação do consentimento. Os seus pedidos serão tratados com especial cuidado de forma a que possamos assegurar a eficácia dos seus direitos. Poderá lhe ser pedido que faça prova da sua identidade de modo a assegurar que a partilha dos Dados Pessoais é apenas feita com o seu titular.</p>
        <p>Você deverá ter em mente que, em certos casos (por exemplo, devido a requisitos legais), o seu pedido poderá não ser imediatamente satisfeito, além de que nós poderemos não conseguir atendê-lo por conta de cumprimento de obrigações legais.</p>
        <p>Você pode nos instruir a qualquer momento para não processar suas informações pessoais para fins de marketing. Apesar de geralmente aceitar com antecedência o uso de suas informações pessoais para fins de marketing, ofereceremos a oportunidade de não permitir este uso no momento de entrada no nosso sistema.</p>
      </section>

      <section>
        <h2>X. Atualização de informações</h2>

        <p>Nos informe a qualquer momento sobre atualizações que deseja fazer em seus dados armazenados.</p>
        <p>Para fins de acesso, alteração e exclusão de dados, você poderá nos contactar através do e-mail contato@arkspace.ai que, com a devida comprovação de titularidade dos dados, atenderá sua solicitação. Os dados aqui descritos poderão ser tratados por nossa empresa, nossas subsidiárias, nossa holding, todas as suas subsidiárias, e todas as outras empresas e/ou agentes descritos na presente Política de Privacidade.</p>
      </section>

      <section>
        <h2>Xl. Sites de terceiros</h2>

        <p>O site poderá, de tempos a tempos, conter links de hipertexto que redirecionará você para sites das redes dos nossos parceiros, anunciantes, fornecedores etc. Se você clicar em um desses links para qualquer um desses sites, lembre-se que cada site possui as suas próprias práticas de privacidade e que não somos responsáveis por essas políticas. Consulte as referidas políticas antes de enviar quaisquer Dados Pessoais para esses sites.</p>
        <p>Não nos responsabilizamos pelas políticas e práticas de coleta, uso e divulgação (incluindo práticas de proteção de dados) de outras organizações, tais como Facebook, Apple, Google, Microsoft, ou de qualquer outro desenvolvedor de software ou provedor de aplicativo, loja de mídia social, sistema operacional, prestador de serviços de internet sem fio ou fabricante de dispositivos, incluindo todos os Dados Pessoais que divulgar para outras organizações por meio dos aplicativos, relacionadas a tais aplicativos, ou publicadas em nossas páginas em mídias sociais. Nós recomendamos que você se informe sobre a política de privacidade de cada site visitado ou de cada prestador de serviço utilizado.</p>
      </section>

      <section>
        <h2>Xll. Coleta automática de Dados Pessoais</h2>

        <p>Quando você visita o site, ele pode armazenar ou recuperar informações em seu navegador, principalmente na forma de cookies, que são arquivos de texto contendo pequenas quantidades de informação. Essas informações podem ser sobre você, suas preferências ou seu dispositivo e são usadas principalmente para que o site funcione como você espera. As informações geralmente não o identificam diretamente, mas podem oferecer uma experiência na internet mais personalizada.</p>
        <p>De acordo com esta Política de Privacidade, nós e nossos prestadores de serviços terceirizados, mediante seu consentimento, podemos coletar seus Dados Pessoais de diversas formas, incluindo, entre outros:</p>

        <div className="topics-group">
          <p className="topic">1. &nbsp; Por meio do navegador ou do dispositivo: algumas informações são coletadas pela maior parte dos navegadores ou automaticamente por meio de dispositivos de acesso à internet, como o tipo de computador, resolução da tela, nome e versão do sistema operacional, modelo e fabricante do dispositivo, idioma, tipo e versão do navegador de Internet que está utilizando. Podemos utilizar essas informações para assegurar que o site funcione adequadamente.</p>
          <p className="topic">2. &nbsp; Uso de cookies: informações sobre o seu uso do site podem ser coletadas por terceiros a partir de cookies. Cookies são informações armazenadas diretamente no computador que você está utilizando. Os cookies permitem a coleta de informações tais como o tipo de navegador, o tempo despendido no site, as páginas visitadas, as preferências de idioma, e outros dados de tráfego anônimos. Nós e nossos prestadores de serviços utilizamos informações para proteção de segurança, para facilitar a navegação, exibir informações de modo mais eficiente, e personalizar sua experiência ao utilizar o site, assim como para rastreamento online. Também coletamos informações estatísticas sobre o uso do site para aprimoramento contínuo do nosso design e funcionalidade, para entender como o site é utilizada e para auxiliá-lo a solucionar questões relativas ao site.</p>
        </div>

        <p>Caso não deseje que suas informações sejam coletadas por meio de cookies, há um procedimento simples na maior parte dos navegadores que permite que os cookies sejam automaticamente rejeitados, ou oferece a opção de aceitar ou rejeitar a transferência de um cookie (ou cookies) específico(s) de um site determinado para o seu computador. Entretanto, isso pode gerar inconvenientes no uso do site.</p>
        <p>As definições que escolher podem afetar a sua experiência de navegação e o funcionamento que exige a utilização de cookies. Neste sentido, rejeitamos qualquer responsabilidade pelas consequências resultantes do funcionamento limitado do site provocado pela desativação de cookies no seu dispositivo (incapacidade de definir ou ler um cookie).</p>

        <div className="topics-group">
          <p className="topic">3. &nbsp; Uso de pixel tags e outras tecnologias similares: pixel tags (também conhecidos como Web beacons e GIFs invisíveis) podem ser utilizados para rastrear ações de usuários do site (incluindo destinatários de e-mails), medir o sucesso das nossas campanhas de marketing e coletar dados estatísticos sobre o uso do site e taxas de resposta, e ainda para outros fins não especificados. Podemos contratar empresas de publicidade comportamental, para obter relatórios sobre os anúncios do site em toda a internet. Para isso, essas empresas utilizam cookies, pixel tags e outras tecnologias para coletar informações sobre a sua utilização, ou sobre a utilização de outros usuários, do nosso site e de site de terceiros. Nós não somos responsáveis por pixel tags, cookies e outras tecnologias similares utilizadas por terceiros.</p>
        </div>
      </section>

      <section>
        <h2>Xlll. Categorias de cookies</h2>

        <p>Os cookies utilizados no nosso site estão de acordo com os requisitos legais e são enquadrados nas seguintes categorias:</p>
        <div className="topics-group">
          <p className="topic">1. &nbsp; Estritamente necessários: estes cookies permitem que você navegue pelo site e desfrute de recursos essenciais com segurança. Um exemplo são os cookies de segurança, que autenticam os usuários, protegem os seus dados e evitam a criação de logins fraudulentos.</p>
          <p className="topic">2. &nbsp; Desempenho: os cookies desta categoria coletam informações de forma codificada e anônima relacionadas à nosso site virtual, como, por exemplo, o número de visitantes de uma página específica, origem das visitas ao site e quais as páginas acessadas pelo usuário. Todos os dados coletados são utilizados apenas para eventuais melhorias no site e para medir a eficácia da nossa comunicação.</p>
          <p className="topic">3. &nbsp; Funcionalidade: estes cookies são utilizados para lembrar definições de preferências do usuário com o objetivo de melhorar a sua visita no nosso site, como, por exemplo, configurações aplicadas no layout do site ou suas respostas para pop-ups de promoções e cadastros -; dessa forma, não será necessário perguntar inúmeras vezes.</p>
          <p className="topic">4. &nbsp; Publicidade: utilizamos cookies com o objetivo de criar campanhas segmentadas e entregar anúncios de acordo com o seu perfil de consumo no nosso site virtual.</p>
        </div>
      </section>

      <section>
        <h2>XlV. Restrição de Idade</h2>

        <p>A Arkspace foi idealizada para consumidoras maiores de idade. Se você tiver menos de 18 anos de idade, não será permitido o envio de suas informações pessoais ao nosso site.</p>
      </section>

      <section>
        <h2>XV. – Contato e dúvidas</h2>

        <p>Caso tenha qualquer dúvida, solicitação ou sugestão sobre este documento, entre em contato com nosso DPO (Data Protection Officer) Felipe Motta, através do endereço de e-mail contato@arkspace.ai.</p>
      </section>
    </div>
  );
}

export default Privacy;
