import { useEffect, useMemo, useState } from "react";
import { smoothScrollTo } from "../../../../utils/smoothScroll";
import Conversation from "../conversation/Conversation";

import { MessageType } from "../../../../utils/types";
import { ConversationProvider } from "../../../../contexts/ConversationContext";

const messages: MessageType[] = [
  { type: 'user', text: 'Preciso dos resultados do meu exame', format: 'text' },
  { type: 'receiver', text: `Claro! 😊 \n\n Para isso, preciso que você me informe seu cpf.`, format: 'text' },
  { type: 'user', text: '12345678998', format: 'text' },
  { type: 'receiver', text: `Aguarde um instante, estou buscando seu documento em meu sistema... ⏳`, format: 'text' },
  { type: 'receiver', text: `Resultados.pdf`, format: 'file' },
  { type: 'receiver', text: `Aqui está! 🌟 \n\n Gostaria de mais alguma ajuda? 🤔`, format: 'text' },
  { type: 'user', text: 'Não, apenas isso', format: 'text' },
  { type: 'receiver', text: `Tudo bem! \n\n Caso precise de ajuda com mais alguma coisa, basta me chamar! 😉`, format: 'text' },
];

const First = () => {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [typewriterSpeed] = useState(150);
  const [shouldStartTyping, setShouldStartTyping] = useState(true);

  const words = useMemo(() => ["Empresa.", "Startup.", "Franquia.", "Agência.", "Consultoria.", "Loja.", "Clínica.", "Distribuídora."], []);

  useEffect(() => {
    const currentWord = words[index];

    // If not supposed to type, return early
    if (!shouldStartTyping) return;

    const handleTyping = () => {
      // Word is fully typed, not yet deleting
      if (!isDeleting && subIndex === currentWord.length + 1) {
        setShouldStartTyping(false); // Pause typing
        setTimeout(() => {
          setIsDeleting(true);  // Start deleting
          setShouldStartTyping(true);  // Resume typing
        }, 2000);
        return;
      }

      // Word is fully deleted
      if (isDeleting && subIndex === 0) {
        setIsDeleting(false);  // Reset deleting flag
        setIndex((prevIndex) => (prevIndex + 1) % words.length);  // Move to the next word
      }

      const nextSubIndex = isDeleting ? subIndex - 1 : subIndex + 1;
      setSubIndex(nextSubIndex);
    };

    const typingTimeout = setTimeout(handleTyping, typewriterSpeed);

    return () => clearTimeout(typingTimeout);
  }, [subIndex, isDeleting, shouldStartTyping, words, index, typewriterSpeed]);

  return (
    <>
      <div className="overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36 mt-12">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
            <div className="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
              <h1 className="text-3xl md:text-5xl tracking-tight text-gray-900 font-semibold">IA Conversacional para sua <span className="underline">{`${words[index].substring(0, subIndex)} `}</span> <span className="cursor-blink inline-block w-1 border-r-2 border-current animate-blink"></span></h1>
              <p className="mt-6 text-lg text-gray-600">Somos uma empresa de Inteligência Artificial Conversacional, com foco em melhorar a experiência de seus clientes e aumentar exponencialmente a conversão de leads. Nossa IA é 100% personalizada para o seu negócio, gerando uma experiência única por meio de conversas incríveis.</p>

              <div className="mt-8 flex flex-wrap gap-x-6 gap-y-4">
                <button onClick={() => smoothScrollTo('contact')} className="inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors border-[#2c2c50] text-gray-700 hover:bg-[#2c2c50] hover:text-white hover:border-[#2c2c50]">
                  <span>Fale com nosso time</span>
                </button>
              </div>
            </div>

            <div className="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
              <div className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0">
                <svg viewBox="0 0 1026 1026" fill="none" aria-hidden="true" className="absolute inset-0 h-full w-full animate-spin-slow">
                  <path d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z" stroke="#D4D4D4" strokeOpacity="0.7"></path>
                  <path d="M513 1025C230.23 1025 1 795.77 1 513" stroke="url(#:S2:-gradient-1)" strokeLinecap="round"></path>
                  <defs>
                    <linearGradient id=":S2:-gradient-1" x1="1" y1="513" x2="1" y2="1025" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#2c2c50"></stop>
                      <stop offset="1" stopColor="#2c2c50" stopOpacity="0"></stop>
                    </linearGradient>
                  </defs>
                </svg>

                <svg viewBox="0 0 1026 1026" fill="none" aria-hidden="true" className="absolute inset-0 h-full w-full animate-spin-reverse-slower">
                  <path d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z" stroke="#D4D4D4" strokeOpacity="0.7"></path>
                  <path d="M913 513c0 220.914-179.086 400-400 400" stroke="url(#:S2:-gradient-2)" strokeLinecap="round"></path>
                  <defs>
                    <linearGradient id=":S2:-gradient-2" x1="913" y1="513" x2="913" y2="913" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#2c2c50"></stop>
                      <stop offset="1" stopColor="#2c2c50" stopOpacity="0"></stop>
                    </linearGradient>
                  </defs>
                </svg>
              </div>

              <div className="-mx-4 h-[448px] px-9 [mask-image:linear-gradient(to_bottom,white_70%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32">
                <div className="relative aspect-[366/729] mx-auto max-w-[366px]">
                  <div className="absolute inset-y-[calc(1/729*100%)] left-[calc(7/729*100%)] right-[calc(5/729*100%)] rounded-[calc(58/366*100%)/calc(58/729*100%)] shadow-2xl"></div>
                  <div className="absolute left-[calc(23/366*100%)] top-[calc(23/729*100%)] grid h-[calc(686/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden bg-[#11111f] pt-[calc(23/318*100%)]">
                    <div className="flex flex-col">
                      <div className="flex justify-center px-4 pt-4">
                        <div className="max-w-[130px]">
                          <img src="/assets/images/brand/brand-image-p-500-white.png" alt="logo" className="max-w-full" />
                        </div>
                      </div>

                      <div className="mt-6 flex-auto rounded-t-2xl bg-white">
                        <div className="p-4">
                          <ConversationProvider initialMessages={messages}>
                            <Conversation className="pb-52 lg:pb-28 h-[500px]" messages={messages} userImage="first-user-profile.png" />
                          </ConversationProvider>
                        </div>
                      </div>

                    </div>
                  </div>
                  <svg viewBox="0 0 366 729" aria-hidden="true" className="pointer-events-none absolute inset-0 h-full w-full fill-gray-100">
                    <path fill="#F2F2F2" fillRule="evenodd" clipRule="evenodd" d="M300.092 1c41.22 0 63.223 21.99 63.223 63.213V184.94c-.173.184-.329.476-.458.851.188-.282.404-.547.647-.791.844-.073 2.496.257 2.496 2.157V268.719c-.406 2.023-2.605 2.023-2.605 2.023a7.119 7.119 0 0 1-.08-.102v394.462c0 41.213-22.001 63.212-63.223 63.212h-95.074c-.881-.468-2.474-.795-4.323-.838l-33.704-.005-.049.001h-.231l-.141-.001c-2.028 0-3.798.339-4.745.843H66.751c-41.223 0-63.223-21.995-63.223-63.208V287.739c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 284.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-16.289c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 214.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-26.041a2.26 2.26 0 0 0 .093-.236l-.064-.01a3.337 3.337 0 0 1-.72-.12l-.166-.028A2 2 0 0 1 1 135.62v-24.611a2 2 0 0 1 1.671-1.973l.857-.143v-44.68C3.528 22.99 25.53 1 66.75 1h233.341ZM3.952 234.516a5.481 5.481 0 0 0-.229-.278c.082.071.159.163.228.278Zm89.99-206.304A4.213 4.213 0 0 0 89.727 24H56.864C38.714 24 24 38.708 24 56.852v618.296C24 693.292 38.714 708 56.864 708h250.272c18.15 0 32.864-14.708 32.864-32.852V56.852C340 38.708 325.286 24 307.136 24h-32.864a4.212 4.212 0 0 0-4.213 4.212v2.527c0 10.235-8.3 18.532-18.539 18.532H112.48c-10.239 0-18.539-8.297-18.539-18.532v-2.527Z"></path>
                    <rect x="154" y="29" width="56" height="5" rx="2.5" fill="#D4D4D4"></rect>
                  </svg>
                  <img alt="" width="366" height="729" decoding="async" data-nimg="1" className="pointer-events-none absolute inset-0 h-full w-full" style={{ "color": "transparent" }} src="/assets/images/phone-frame.d4b6b62a.svg" />
                </div>
              </div>
            </div>
            <div className="relative -mt-4 lg:col-span-7 lg:mt-0 xl:col-span-6">
              <p className="text-center text-sm mt-16 lg:mt-0 font-semibold text-gray-900 lg:text-left">Suporte para mídias sociais</p>
              <ul className="mx-auto mt-8 flex max-w-xl flex-wrap justify-center gap-x-10 gap-y-8 lg:mx-0 lg:justify-start">
                <li className="flex">
                  <img alt="Whatsapp" loading="lazy" width="30" height="32" decoding="async" data-nimg="1" className="h-8" style={{ "color": "transparent" }} src="/assets/images/social/whatsapp.svg" />
                </li>
                <li className="flex">
                  <img alt="Facebook" loading="lazy" width="30" height="32" decoding="async" data-nimg="1" className="h-8" style={{ "color": "transparent" }} src="/assets/images/social/instagram.svg" />
                </li>
                <li className="flex">
                  <img alt="Instagram" loading="lazy" width="30" height="32" decoding="async" data-nimg="1" className="h-8" style={{ "color": "transparent" }} src="/assets/images/social/facebook.svg" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default First;