import React, { useEffect, useRef } from 'react';
import { useConversation } from '../../../../contexts/ConversationContext';

import TextMessage from './TextMessage';
import AudioMessage from './AudioMessage';
import TypingIndicator from './TypingIndicator';

import { MessageType } from '../../../../utils/types';

type ConversationType = {
  className?: string;
  messages: MessageType[];
  userImage: string;
  userBgColor?: string;
  textColor?: string;
};

const Conversation: React.FC<ConversationType> = ({ className, messages, userImage, userBgColor, textColor }) => {
  const { displayedMessages, showTyping } = useConversation();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  // conversation always scrolls to bottom
  useEffect(scrollToBottom, [displayedMessages]);

  const currentTypingType = displayedMessages.length < messages.length ? messages[displayedMessages.length]?.type : null;

  return (
    <div className={`flex flex-col overflow-y-auto p-4 ${className} bg-gray-100 rounded-lg scrollbar scrollbar-none`} ref={messagesEndRef}>
      {displayedMessages.map((msg, idx) => {
        if (msg.format === 'audio') {
          return (
            <TextMessage key={idx} type={msg.type} userImage={userImage} userBgColor={userBgColor} textColor={textColor}>
              <AudioMessage duration={msg.text} />
            </TextMessage>
          );
        } else if (msg.format === 'file') {
          return (
            <TextMessage key={idx} type={msg.type} userImage={userImage} userBgColor={userBgColor} textColor={textColor}>
              <div className="flex flex-row items-center p-2 bg-gray-200 rounded rounded-md h-8 w-full">
                <img className='h-5 w-5' alt="pdf" src="/assets/images/conversation/pdf-icon.svg" />
                <p className='pl-2'>{msg.text}</p>
              </div>
            </TextMessage>
          );
        }

        return (
          <TextMessage key={idx} text={msg.text} type={msg.type} userImage={userImage} userBgColor={userBgColor} textColor={textColor} />
        );
      })}

      {currentTypingType && (
        <div className={`flex ${currentTypingType === 'user' ? 'self-end' : 'self-start'} items-end`}>
          {currentTypingType === 'receiver' ? <img src="/assets/images/conversation/bot-profile.png" alt="ai-profile" className="h-8 w-8 mr-2 rounded-full" /> : null}
          <TypingIndicator show={showTyping} type={currentTypingType} />
          {currentTypingType === 'user' ? <img src={`/assets/images/conversation/${userImage}`} alt="user_image" className="h-8 w-8 ml-2 rounded-full" /> : null}
        </div>
      )}
    </div>
  );
};

export default Conversation;