import { toast } from "react-toastify";

export const toastErrors = (toastId: any, errors: any) => {
  if (errors.message) {
    return toast.update(toastId, {
      render: errors.message,
      type: "error",
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true
    });
  }
  toast.update(toastId, {
    render: "Ocorreu um erro.",
    type: "error",
    isLoading: false,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true
  });
}