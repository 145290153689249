import styles from '../styles/components/loadingDots.module.css';

export const LoadingDots = ({ color = "#000", dotStyle = "small" }: { color: string; dotStyle: string; }) => {
  return (
    <span className={dotStyle === "small" ? styles.loading2 : styles.loading}>
      <span style={{ backgroundColor: color }} />
      <span style={{ backgroundColor: color }} />
      <span style={{ backgroundColor: color }} />
    </span>
  );
};

LoadingDots.defaultProps = {
  style: "small"
};
