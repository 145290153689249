import React from 'react';

type TypingIndicatorProps = {
  show: boolean;
  type: string;
};

const TypingIndicator: React.FC<TypingIndicatorProps> = ({ show, type }) => {
  const alignment = type === 'user' ? 'chat-end' : 'chat-start';

  return (
    <div className={`chat ${alignment}`}>
      <div className={`flex items-center space-x-2 p-3 chat-bubble bg-gray-300 text-black shadow-lg rounded-lg ${show ? 'opacity-100 animate-pulse' : 'opacity-0'}`}>
        <span className="loading loading-dots loading-md"></span>
      </div>
    </div>
  );
};

export default TypingIndicator;