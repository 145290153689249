import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { MessageType } from '../utils/types';

type ConversationContextType = {
  displayedMessages: MessageType[];
  nextStep: () => void;
  showTyping: boolean;
};

const ConversationContext = createContext<ConversationContextType | undefined>(undefined);

export const useConversation = () => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error("useConversation must be used within a ConversationProvider");
  }
  return context;
};

export const ConversationProvider: React.FC<{ children: React.ReactNode, initialMessages?: MessageType[] }> = ({ children, initialMessages = [] }) => {
  const [displayedMessages, setDisplayedMessages] = useState<MessageType[]>([]);
  const [showTyping, setShowTyping] = useState(true);

  const nextStep = useCallback(() => {

    if (showTyping) {
      // If currently typing, show the message next
      setDisplayedMessages((prev) => [...prev, initialMessages[prev.length]]);
      setShowTyping(false);
    } else {
      if (displayedMessages.length < initialMessages.length) {
        // If there are still messages left, show typing next
        setShowTyping(true);
      }
    }
  }, [showTyping, displayedMessages, initialMessages]);

  useEffect(() => {
    if (showTyping) {
      const timeoutId = setTimeout(() => {
        setShowTyping(false);

        // Add a short delay here to let the typing animation disappear
        // before the new message fades in
        const transitionTimeout = setTimeout(nextStep, 300);

        return () => clearTimeout(transitionTimeout);
      }, 800);

      return () => clearTimeout(timeoutId);
    } else {
      const timeout = setTimeout(nextStep, 1000);
      return () => clearTimeout(timeout);
    }
  }, [showTyping, displayedMessages, nextStep]);

  return (
    <ConversationContext.Provider value={{ displayedMessages, nextStep, showTyping }}>
      {children}
    </ConversationContext.Provider>
  );
};
