import { useEffect, useRef, useState } from "react";
import Conversation from "../conversation/Conversation";

import { MessageType } from "../../../../utils/types";
import { ConversationProvider } from "../../../../contexts/ConversationContext";
import React from "react";

interface ContentScreenProps {
  children: React.ReactNode;
}

const messages: MessageType[] = [
  { type: 'user', text: 'Estou nervoso com meu exame de sangue. O que devo fazer antes?', format: 'text' },
  { type: 'receiver', text: `Compreendo sua preocupação. 😕 \n\n Mas fique tranquilo! \n\n Para que seu exame de sangue seja 100% assertivo, basta seguir os seguintes passos: \n\n ➡️ Evite comer ou beber até 8h antes do exame de sangue 🍴 \n\n ➡️ Não consuma álcool até 24h antes 🍺 \n\n Ah! E siga todas as orientações do seu médico. 😊`, format: 'text' },
  { type: 'user', text: 'Obrigado, isso ajuda!', format: 'text' },
  { type: 'receiver', text: 'De nada! \n\nSe tiver mais alguma pergunta, estou aqui para te ajudar. 😉', format: 'text' }
];

const messages1: MessageType[] = [
  { type: 'user', text: 'Preciso da segunda via do meu boleto', format: 'text' },
  { type: 'receiver', text: `Claro, posso te ajudar com isso. \n\n Por favor, me forneça o número do pedido. 🔢`, format: 'text' },
  { type: 'user', text: 'O número é 123456', format: 'text' },
  { type: 'receiver', text: `Encontrei seu pedido. \n\n Aqui está o link para baixar a segunda via do boleto: \n\n empresa.com.br/imprimir-boleto. 🔗`, format: 'text' },
  { type: 'user', text: 'Perfeito, obrigado!', format: 'text' },
  { type: 'user', text: 'Aproveitando que estou aqui, estou procurando um smartphone novo. Você pode me mostrar algumas opções?', format: 'text' },
  { type: 'receiver', text: `Certamente! \n\n Que tipo de smartphone você prefere: Android ou iOS? 📱`, format: 'text' },
  { type: 'user', text: 'Prefiro android', format: 'text' },
  { type: 'receiver', text: `Ótimo! \n\n Temos várias opções Android disponíveis. \n\n Qual seu orçamento? 💵`, format: 'text' },
  { type: 'user', text: 'Estou disposto a gastar até R$ 2.200', format: 'text' },
  { type: 'receiver', text: `Com base no seu orçamento, recomendo o modelo Samsung A54. \n\n Ele possui câmera traseira tripla de 50MP + 12MP + 5MP e câmera frontal de 32MP. 📷 \n\n Permitindo que você registre suas memórias com a máxima qualidade. 🤩 \n\n Que tal adquirir seu novo celular pelo menor preço do mercado? 🤔`, format: 'text' },
  { type: 'user', text: 'Quero comprar agora', format: 'text' },
  { type: 'receiver', text: `Perfeito! 🌟 \n\n Você pode finalizar sua compra pelo link a seguir: \n\n empresa.com.br/finalizar-compra-samsung-a54`, format: 'text' },
];

const messages2: MessageType[] = [
  { type: 'user', text: 'Quero saber quando vou receber minha encomenda', format: 'text' },
  { type: 'receiver', text: `Claro, posso te ajudar com isso! 😊 \n\n Por favor, me forneça o número do pedido. 🔢`, format: 'text' },
  { type: 'user', text: 'É 123456', format: 'text' },
  { type: 'receiver', text: `Encontrei seu pedido. 😃 \n\n O pedido 123456 já foi faturado e está aguardando ser retirado pela transportadora. 🚚 \n\n Você também pode acompanhar sua entrega pelo link a seguir: \n\n empresa.com.br/status-pedido 🔗`, format: 'text' },
  { type: 'user', text: 'Muito obrigado', format: 'text' },
  { type: 'receiver', text: 'Por nada, se tiver mais alguma dúvida estou aqui para ajudá-lo. 😊', format: 'text' },
];

const messages3: MessageType[] = [
  { type: 'user', text: 'Olá, gostaria de agendar uma consulta médica para a próxima semana', format: 'text' },
  { type: 'receiver', text: `Claro! \n\n Por favor, me informe o motivo da consulta e sua disponibilidade de horário. 📆`, format: 'text' },
  { type: 'user', text: 'Preciso de uma consulta com um cardiologista. Tenho disponibilidade na terça de manhã', format: 'text' },
  { type: 'receiver', text: `Ótimo! \n\n Encontrei um horário disponível na terça-feira às 10h com o Dr. Silva. \n\n Posso reservar para você? 🤔`, format: 'text' },
  { type: 'user', text: 'Sim, por favor!', format: 'text' },
  { type: 'receiver', text: `Sua consulta com o Dr. Silva foi agendada para terça-feira às 10h. ✅ \n\n Alguma outra pergunta?`, format: 'text' },
  { type: 'user', text: 'Não, obrigado', format: 'text' },
  { type: 'receiver', text: `Obrigado pelo contato! \n\n Caso precise de alguma ajuda, basta me chamar. 😉`, format: 'text' },
];

const messages4: MessageType[] = [
  { type: 'user', text: 'Gostaria de solicitar minhas férias para o próximo mês', format: 'text' },
  { type: 'receiver', text: `Claro, posso ajudar com isso! 🌟 \n\n Por favor, me informe a data de início e término das suas férias. 📆`, format: 'text' },
  { type: 'user', text: 'Quero começar minhas férias em 15 de outubro e terminar em 30 de outubro', format: 'text' },
  { type: 'receiver', text: `Aguarde uns instantes, estou verificando a disponibilidade deste período... ⏳`, format: 'text' },
  { type: 'receiver', text: `Oba! Suas férias foram enviadas para aprovação da gestão. \n\n Alguma outra solicitação? 🤔`, format: 'text' },
  { type: 'user', text: 'Sim, preciso do meu holerite do mês', format: 'text' },
  { type: 'receiver', text: `Vou gerar seu holerite e enviá-lo no email cadastrado. Algo mais? 🤔`, format: 'text' },
  { type: 'user', text: 'Não, obrigada', format: 'text' },
  { type: 'receiver', text: `Certo! \n\n Caso precise de mais alguma ajuda, basta me enviar mensagem. 😉`, format: 'text' },
];

const messages5: MessageType[] = [
  { type: 'user', text: '0:18', format: 'audio' },
  { type: 'receiver', text: '0:14', format: 'audio' },
  { type: 'user', text: '0:03', format: 'audio' },
  { type: 'receiver', text: '0:05', format: 'audio' }
];

const Second = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [animationSM, setAnimationSM] = useState(true);

  const tabRefs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];
  const screenRefs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];

  const ScreenWrapper = React.forwardRef<HTMLDivElement, { children: React.ReactNode, className: string }>(
    ({ children, className }, ref) => {
      return (
        <div ref={ref} className={className}>
          {children}
        </div>
      );
    }
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 728) {
        setAnimationSM(false);
      } else {
        setAnimationSM(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ContentScreen: React.FC<ContentScreenProps> = ({ children }) => {
    return (
      <div className={`w-full h-full flex transition-all animate-fadeInBottom`}>
        {children}
      </div>
    );
  };

  const Screen: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
      <>
        {animationSM ? <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <svg viewBox="0 0 558 558" width="558" height="558" fill="none" aria-hidden="true" className="animate-spin-slow">
            <defs>
              <linearGradient id=":Rddaqlla:" x1="79" y1="16" x2="105" y2="237" gradientUnits="userSpaceOnUse">
                <stop stopColor="#13B5C8"></stop>
                <stop offset="1" stopColor="#13B5C8" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
            <path opacity=".2" d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z" stroke="#13B5C8"></path>
            <path d="M1 279C1 125.465 125.465 1 279 1" stroke="url(#:Rddaqlla:)" strokeLinecap="round"></path>
          </svg>
        </div> : null}
        <div className="relative aspect-[366/729] md:mb-0 relative mx-auto w-full max-w-[366px] screen-content">
          <div className="absolute inset-y-[calc(1/729*100%)] left-[calc(7/729*100%)] right-[calc(5/729*100%)] rounded-[calc(58/366*100%)/calc(58/729*100%)] shadow-2xl"></div>
          <div className="absolute left-[calc(23/366*100%)] top-[calc(23/729*100%)] grid h-[calc(686/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden bg-gray-900 pt-[calc(23/318*100%)]">

            <div className="flex flex-col w-full">
              <div className="flex justify-center px-4 pt-4">
                <div className="max-w-[130px]">
                  <img src="/assets/images/brand/brand-image-p-500-white.png" alt="logo" className="max-w-full" />
                </div>
              </div>
              {children}

            </div>
          </div>
          <svg viewBox="0 0 366 729" aria-hidden="true" className="pointer-events-none absolute inset-0 h-full w-full fill-gray-100">
            <path fill="#F2F2F2" fillRule="evenodd" clipRule="evenodd" d="M300.092 1c41.22 0 63.223 21.99 63.223 63.213V184.94c-.173.184-.329.476-.458.851.188-.282.404-.547.647-.791.844-.073 2.496.257 2.496 2.157V268.719c-.406 2.023-2.605 2.023-2.605 2.023a7.119 7.119 0 0 1-.08-.102v394.462c0 41.213-22.001 63.212-63.223 63.212h-95.074c-.881-.468-2.474-.795-4.323-.838l-33.704-.005-.049.001h-.231l-.141-.001c-2.028 0-3.798.339-4.745.843H66.751c-41.223 0-63.223-21.995-63.223-63.208V287.739c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 284.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-16.289c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 214.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-26.041a2.26 2.26 0 0 0 .093-.236l-.064-.01a3.337 3.337 0 0 1-.72-.12l-.166-.028A2 2 0 0 1 1 135.62v-24.611a2 2 0 0 1 1.671-1.973l.857-.143v-44.68C3.528 22.99 25.53 1 66.75 1h233.341ZM3.952 234.516a5.481 5.481 0 0 0-.229-.278c.082.071.159.163.228.278Zm89.99-206.304A4.213 4.213 0 0 0 89.727 24H56.864C38.714 24 24 38.708 24 56.852v618.296C24 693.292 38.714 708 56.864 708h250.272c18.15 0 32.864-14.708 32.864-32.852V56.852C340 38.708 325.286 24 307.136 24h-32.864a4.212 4.212 0 0 0-4.213 4.212v2.527c0 10.235-8.3 18.532-18.539 18.532H112.48c-10.239 0-18.539-8.297-18.539-18.532v-2.527Z"></path>
            <rect x="154" y="29" width="56" height="5" rx="2.5" fill="#D4D4D4"></rect>
          </svg>
          <img alt="" loading="lazy" width="366" height="729" decoding="async" data-nimg="1" className="pointer-events-none absolute inset-0 h-full w-full" style={{ "color": "transparent" }} src="/assets/images/phone-frame.d4b6b62a.svg" />
        </div>
      </>
    )
  }

  const ItemDescription: React.FC<{ children: React.ReactNode, title: string, description: string }> = ({ children, title, description }) => {
    return (
      <div className="inset-x-0 bottom-0 bg-gray-800/95 py-6 px-4 md:px-6 backdrop-blur sm:p-10">
        <div className="flex flex-row items-center">
          {children}
          <h3 className="text-sm ml-4 font-semibold text-white sm:text-lg">{title}</h3>
        </div>
        <p className="mt-6 text-sm text-gray-400">{description}</p>
      </div>
    )
  }

  const FirstScreen = () => {
    return (
      <Screen>
        <ContentScreen>
          <div className="mt-6 flex-auto rounded-t-2xl bg-white">
            <div className="px-4 py-6">
              <ConversationProvider initialMessages={messages}>
                <Conversation className="absolute left-[calc(23/366*100%)] h-[calc(566/729*100%)] md:h-[calc(580/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden" messages={messages} userImage="second-user-profile.png" userBgColor="bg-[#833ab4]" textColor="white" />
              </ConversationProvider>
            </div>
          </div>
        </ContentScreen>
      </Screen>
    )
  }

  const SecondScreen = () => {
    return (
      <Screen>
        <ContentScreen>
          <div className="mt-6 flex-auto rounded-t-2xl bg-white">
            <div className="px-4 py-6">
              <ConversationProvider initialMessages={messages1}>
                <Conversation className="absolute left-[calc(23/366*100%)] h-[calc(566/729*100%)] md:h-[calc(580/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden" messages={messages1} userImage="third-user-profile.png" userBgColor="bg-[#1877f2]" textColor="white" />
              </ConversationProvider>
            </div>
          </div>
        </ContentScreen>
      </Screen>
    )
  }

  const ThirdScreen = () => {
    return (
      <Screen>
        <ContentScreen>
          <div className="mt-6 flex-auto rounded-t-2xl bg-white">
            <div className="px-4 py-6">
              <ConversationProvider initialMessages={messages2}>
                <Conversation className="absolute left-[calc(23/366*100%)] h-[calc(566/729*100%)] md:h-[calc(580/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden" messages={messages2} userImage="fourth-user-profile.png" />
              </ConversationProvider>
            </div>
          </div>
        </ContentScreen>
      </Screen>
    )
  }

  const FourthScreen = () => {
    return (
      <Screen>
        <ContentScreen>
          <div className="mt-6 flex-auto rounded-t-2xl bg-white">
            <div className="px-4 py-6">
              <ConversationProvider initialMessages={messages3}>
                <Conversation className="absolute left-[calc(23/366*100%)] h-[calc(566/729*100%)] md:h-[calc(580/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden" messages={messages3} userImage="fourth-user-profile.png" userBgColor="bg-[#1877f2]" textColor="white" />
              </ConversationProvider>
            </div>
          </div>
        </ContentScreen>
      </Screen>
    )
  }

  const FifthScreen = () => {
    return (
      <Screen>
        <ContentScreen>
          <div className="mt-6 flex-auto rounded-t-2xl bg-white">
            <div className="px-4 py-6">
              <ConversationProvider initialMessages={messages4}>
                <Conversation className="absolute left-[calc(23/366*100%)] h-[calc(566/729*100%)] md:h-[calc(580/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden" messages={messages4} userImage="first-user-profile.png" />
              </ConversationProvider>
            </div>
          </div>
        </ContentScreen>
      </Screen>
    )
  }

  const SixthScreen = () => {
    return (
      <Screen>
        <ContentScreen>
          <div className="mt-6 flex-auto rounded-t-2xl bg-white">
            <div className="px-4 py-6">
              <ConversationProvider initialMessages={messages5}>
                <Conversation className="absolute left-[calc(23/366*100%)] h-[calc(566/729*100%)] md:h-[calc(580/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden" messages={messages5} userImage="fourth-user-profile.png" />
              </ConversationProvider>
            </div>
          </div>
        </ContentScreen>
      </Screen>
    )
  }

  const screens = [
    <ScreenWrapper ref={screenRefs[0]} className="visible"><FirstScreen /></ScreenWrapper>,
    <ScreenWrapper ref={screenRefs[1]} className="hidden"><SecondScreen /></ScreenWrapper>,
    <ScreenWrapper ref={screenRefs[2]} className="hidden"><ThirdScreen /></ScreenWrapper>,
    <ScreenWrapper ref={screenRefs[3]} className="hidden"><FourthScreen /></ScreenWrapper>,
    <ScreenWrapper ref={screenRefs[4]} className="hidden"><FifthScreen /></ScreenWrapper>,
    <ScreenWrapper ref={screenRefs[5]} className="hidden"><SixthScreen /></ScreenWrapper>,
  ];

  const toggleActiveContent = (e: React.MouseEvent<HTMLDivElement>) => {
    // Make the content of the clicked tab visible and change its background
    const contentDiv = e.currentTarget.querySelector('.tab-content');
    if (contentDiv) {
      contentDiv.classList.remove('hidden');
      contentDiv.classList.add('visible');
    }
    e.currentTarget.classList.add('bg-gray-800');
    e.currentTarget.classList.remove('hover:bg-gray-800/50');

    tabRefs.forEach((ref, idx) => {
      const isCurrentTab = ref.current === e.currentTarget;

      // Hide content for all other tabs and reset their backgrounds
      const otherContentDiv = ref.current?.querySelector('.tab-content');
      if (otherContentDiv) {
        if (isCurrentTab) {
          otherContentDiv.classList.remove('hidden');
          otherContentDiv.classList.add('visible');
        } else {
          otherContentDiv.classList.add('hidden');
          otherContentDiv.classList.remove('visible');
          ref.current?.classList.remove('bg-gray-800');
          ref.current?.classList.add('hover:bg-gray-800/50')
        }
      }

      // Show or hide screens
      const screenDiv = screenRefs[idx].current;
      if (screenDiv) {
        if (isCurrentTab) {
          screenDiv.classList.remove('hidden');
          screenDiv.classList.add('visible');
        } else {
          screenDiv.classList.add('hidden');
          screenDiv.classList.remove('visible');
        }
      }
    });
  };

  return (
    <section id="solutions" aria-label="Features for investing all your money" className="bg-[#11111f] py-20 sm:py-32">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-3xl">
          <h2 className="text-3xl font-medium tracking-tight text-white">Crie experiências únicas com nossas soluções de Inteligência Artificial Conversacional.</h2>
          <p className="mt-2 text-lg text-gray-400 md:hidden">Arraste para o lado e veja algumas das várias soluções que oferecemos!</p>
        </div>
      </div>
      <div className="mt-12 md:hidden">
        <div ref={containerRef} className="-mb-4 scroll-container flex items-end snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 scrollbar scrollbar-none">

          <div className="w-full flex-none snap-center px-4 sm:px-6 el-snap _1">
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 p-3 mb-3">
              <ItemDescription title="Atendimento ao cliente completo" description="Atendimento completo e automatizado para tirar dúvidas de seus clientes sobre sua empresa com linguagem natural, melhorando a interação e a relação dos seus clientes com sua marca.">
                <svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z" fill="#737373"></path>
                  <path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z" fill="#A3A3A3"></path>
                </svg>
              </ItemDescription>
            </div>
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 px-5 py-6">
              <FirstScreen />
            </div>
          </div>

          <div className="w-full flex-none snap-center px-4 sm:px-6 el-snap _2">
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 p-3 mb-3">
              <ItemDescription title="Vendas" description="Recomendação de produtos de forma personalizada, entendendo a necessidade de seus clientes para aumentar a conversão. Também pode ser fornecido para seu cliente segunda via de boletos para concretizar a venda!">
                <svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z" fill="#A3A3A3"></path>
                  <path d="M9 8a2 2 0 012-2h10a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2V8z" fill="#737373"></path>
                </svg>
              </ItemDescription>
            </div>
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 px-5 py-6">
              <SecondScreen />
            </div>
          </div>

          <div className="w-full flex-none snap-center px-4 sm:px-6 el-snap _3">
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 p-3 mb-3">
              <ItemDescription title="Pós-vendas" description="Soluções completas para seus clientes após a compra, possibilitando que eles verifiquem instantâneamente por meio do chat o status do pedido, solicitem trocas ou devoluções, solicitem notas fiscais e muito mais! Tudo isso 100% automatizado e personalizado para o seu negócio!">
                <svg viewBox="0 0 32 32" fill="none" aria-hidden="true" className="h-8 w-8">
                  <defs>
                    <linearGradient id=":R1upaqlla:-gradient" x1="14" y1="14.5" x2="7" y2="17" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#737373"></stop>
                      <stop offset="1" stopColor="#D4D4D4" stopOpacity="0"></stop>
                    </linearGradient>
                  </defs>
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v13h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h4v2H9a4 4 0 01-4-4V4z" fill="#A3A3A3"></path>
                  <path d="M7 22c0-4.694 3.5-8 8-8" stroke="url(#:R1upaqlla:-gradient)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M21 20l.217-5.513a1.431 1.431 0 00-2.85-.226L17.5 21.5l-1.51-1.51a2.107 2.107 0 00-2.98 0 .024.024 0 00-.005.024l3.083 9.25A4 4 0 0019.883 32H25a4 4 0 004-4v-5a3 3 0 00-3-3h-5z" fill="#A3A3A3"></path>
                </svg>
              </ItemDescription>
            </div>
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 px-5 py-6">
              <ThirdScreen />
            </div>
          </div>

          <div className="w-full flex-none snap-center px-4 sm:px-6 el-snap _4">
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 p-3 mb-3">
              <ItemDescription title="Agendamentos automatizados" description="Agendamentos de forma interativa e automatizada, possibilitando que seus clientes agendem qualquer coisa por meio do chat, desde consultas médicas até revisões de carro.">
                <svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z" fill="#A3A3A3"></path>
                  <path d="M9 8a2 2 0 012-2h10a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2V8z" fill="#737373"></path>
                </svg>
              </ItemDescription>
            </div>
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 px-5 py-6">
              <FourthScreen />
            </div>
          </div>

          <div className="w-full flex-none snap-center px-4 sm:px-6 el-snap _5">
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 p-3 mb-3">
              <ItemDescription title="Recursos Humanos" description="A IA Conversacional pode contribuir com a agilidade tanto para com o colaborador da sua empresa, quanto para o profissional de RH. Possibilitando agendamento de férias, solicitação de holerite, sanar dúvidas etc.">
                <svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z" fill="#737373"></path>
                  <path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z" fill="#A3A3A3"></path>
                </svg>
              </ItemDescription>
            </div>
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 px-5 py-6">
              <FifthScreen />
            </div>
          </div>

          <div className="w-full flex-none snap-center px-4 sm:px-6 el-snap _6">
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 p-3 mb-3">
              <ItemDescription title="Acessibilidade para PcDs" description="Nossa IA Conversacional entende áudios e responde por áudios, possibilitando que você responda todos os seus clientes!">
                <svg viewBox="0 0 32 32" fill="none" aria-hidden="true" className="h-8 w-8">
                  <defs>
                    <linearGradient id=":R1upaqlla:-gradient" x1="14" y1="14.5" x2="7" y2="17" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#737373"></stop>
                      <stop offset="1" stopColor="#D4D4D4" stopOpacity="0"></stop>
                    </linearGradient>
                  </defs>
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v13h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h4v2H9a4 4 0 01-4-4V4z" fill="#A3A3A3"></path>
                  <path d="M7 22c0-4.694 3.5-8 8-8" stroke="url(#:R1upaqlla:-gradient)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M21 20l.217-5.513a1.431 1.431 0 00-2.85-.226L17.5 21.5l-1.51-1.51a2.107 2.107 0 00-2.98 0 .024.024 0 00-.005.024l3.083 9.25A4 4 0 0019.883 32H25a4 4 0 004-4v-5a3 3 0 00-3-3h-5z" fill="#A3A3A3"></path>
                </svg>
              </ItemDescription>
            </div>
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 px-5 py-6">
              <SixthScreen />
            </div>
          </div>

        </div>
      </div >

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 hidden md:mt-20 md:block">
        <div className="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24">
          <div className="relative z-10 order-last col-span-6 space-y-4" role="tablist" aria-orientation="vertical">

            <div ref={tabRefs[0]} className="relative rounded-2xl transition-colors bg-gray-800" onClick={toggleActiveContent}>
              <div className="flex flex-row items-center relative z-10 p-8">
                <svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z" fill="#737373"></path>
                  <path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z" fill="#A3A3A3"></path>
                </svg>
                <h3 className="text-lg font-semibold text-white">
                  <button className="text-left ml-3 ui-not-focus-visible:outline-none">
                    <span className="absolute inset-0 rounded-2xl"></span>
                    Atendimento ao cliente completo
                  </button>
                </h3>
              </div>
              <div className={`z-10 px-8 pb-8 -mt-4 tab-content`}>
                <p className="mt-2 text-sm text-gray-400 animate-fadeInBottom">Atendimento completo e automatizado para tirar dúvidas de seus clientes sobre sua empresa com linguagem natural, melhorando a interação e a relação dos seus clientes com sua marca.</p>
              </div>
            </div>

            <div ref={tabRefs[1]} className="relative rounded-2xl transition-colors hover:bg-gray-800/50" onClick={toggleActiveContent}>
              <div className="flex flex-row items-center relative z-10 p-8">
                <svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z" fill="#A3A3A3"></path>
                  <path d="M9 8a2 2 0 012-2h10a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2V8z" fill="#737373"></path>
                </svg>
                <h3 className="text-lg font-semibold text-white">
                  <button className="text-left ml-3 ui-not-focus-visible:outline-none">
                    <span className="absolute inset-0 rounded-2xl"></span>
                    Vendas
                  </button>
                </h3>
              </div>
              <div className={`z-10 px-8 pb-8 -mt-4 tab-content hidden`}>
                <p className="mt-2 text-sm text-gray-400 animate-fadeInBottom">Recomendação de produtos de forma personalizada, entendendo a necessidade de seus clientes para aumentar a conversão. Também pode ser fornecido para seu cliente segunda via de boletos para concretizar a venda!</p>
              </div>
            </div>

            <div ref={tabRefs[2]} className="relative rounded-2xl transition-colors hover:bg-gray-800/50" onClick={toggleActiveContent}>
              <div className="flex flex-row items-center relative z-10 p-8">
                <svg viewBox="0 0 32 32" fill="none" aria-hidden="true" className="h-8 w-8">
                  <defs>
                    <linearGradient id=":R6rdaqlla:-gradient" x1="14" y1="14.5" x2="7" y2="17" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#737373"></stop>
                      <stop offset="1" stopColor="#D4D4D4" stopOpacity="0"></stop>
                    </linearGradient>
                  </defs>
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v13h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h4v2H9a4 4 0 01-4-4V4z" fill="#A3A3A3"></path>
                  <path d="M7 22c0-4.694 3.5-8 8-8" stroke="url(#:R6rdaqlla:-gradient)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M21 20l.217-5.513a1.431 1.431 0 00-2.85-.226L17.5 21.5l-1.51-1.51a2.107 2.107 0 00-2.98 0 .024.024 0 00-.005.024l3.083 9.25A4 4 0 0019.883 32H25a4 4 0 004-4v-5a3 3 0 00-3-3h-5z" fill="#A3A3A3"></path>
                </svg>
                <h3 className="text-lg font-semibold text-white">
                  <button className="text-left ml-3 ui-not-focus-visible:outline-none">
                    <span className="absolute inset-0 rounded-2xl"></span>
                    Pós-vendas
                  </button>
                </h3>
              </div>
              <div className="z-10 px-8 pb-8 -mt-4 tab-content hidden">
                <p className="mt-2 text-sm text-gray-400 animate-fadeInBottom">Soluções completas para seus clientes após a compra, possibilitando que eles verifiquem instantâneamente por meio do chat o status do pedido, solicitem trocas ou devoluções, solicitem notas fiscais e muito mais! Tudo isso 100% automatizado e personalizado para o seu negócio!</p>
              </div>
            </div>

            <div ref={tabRefs[3]} className="relative rounded-2xl transition-colors hover:bg-gray-800/50" onClick={toggleActiveContent}>
              <div className="flex flex-row items-center relative z-10 p-8">
                <svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z" fill="#A3A3A3"></path>
                  <path d="M9 8a2 2 0 012-2h10a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2V8z" fill="#737373"></path>
                </svg>
                <h3 className="text-lg font-semibold text-white">
                  <button className="text-left ml-3 ui-not-focus-visible:outline-none">
                    <span className="absolute inset-0 rounded-2xl"></span>
                    Agendamentos automatizados
                  </button>
                </h3>
              </div>
              <div className="z-10 px-8 pb-8 -mt-4 tab-content hidden">
                <p className="mt-2 text-sm text-gray-400 animate-fadeInBottom">Agendamentos de forma interativa e automatizada, possibilitando que seus clientes agendem qualquer coisa por meio do chat, desde consultas médicas até revisões de carro.</p>
              </div>
            </div>

            <div ref={tabRefs[4]} className="relative rounded-2xl transition-colors hover:bg-gray-800/50" onClick={toggleActiveContent}>
              <div className="flex flex-row items-center relative z-10 p-8">
                <svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z" fill="#737373"></path>
                  <path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z" fill="#A3A3A3"></path>
                </svg>
                <h3 className="text-lg font-semibold text-white">
                  <button className="text-left ml-3 ui-not-focus-visible:outline-none">
                    <span className="absolute inset-0 rounded-2xl"></span>
                    Recursos Humanos
                  </button>
                </h3>
              </div>
              <div className="z-10 px-8 pb-8 -mt-4 tab-content hidden">
                <p className="mt-2 text-sm text-gray-400 animate-fadeInBottom">A IA Conversacional pode contribuir com a agilidade tanto para com o colaborador da sua empresa, quanto para o profissional de RH. Possibilitando agendamento de férias, solicitação de holerite, sanar dúvidas etc.</p>
              </div>
            </div>

            <div ref={tabRefs[5]} className="relative rounded-2xl transition-colors hover:bg-gray-800/50" onClick={toggleActiveContent}>
              <div className="flex flex-row items-center relative z-10 p-8">
                <svg viewBox="0 0 32 32" fill="none" aria-hidden="true" className="h-8 w-8">
                  <defs>
                    <linearGradient id=":R6rdaqlla:-gradient" x1="14" y1="14.5" x2="7" y2="17" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#737373"></stop>
                      <stop offset="1" stopColor="#D4D4D4" stopOpacity="0"></stop>
                    </linearGradient>
                  </defs>
                  <circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
                  <path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v13h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h4v2H9a4 4 0 01-4-4V4z" fill="#A3A3A3"></path>
                  <path d="M7 22c0-4.694 3.5-8 8-8" stroke="url(#:R6rdaqlla:-gradient)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M21 20l.217-5.513a1.431 1.431 0 00-2.85-.226L17.5 21.5l-1.51-1.51a2.107 2.107 0 00-2.98 0 .024.024 0 00-.005.024l3.083 9.25A4 4 0 0019.883 32H25a4 4 0 004-4v-5a3 3 0 00-3-3h-5z" fill="#A3A3A3"></path>
                </svg>
                <h3 className="text-lg font-semibold text-white">
                  <button className="text-left ml-3 ui-not-focus-visible:outline-none">
                    <span className="absolute inset-0 rounded-2xl"></span>
                    Acessibilidade para PCDs
                  </button>
                </h3>
              </div>
              <div className="z-10 px-8 pb-8 -mt-4 tab-content hidden">
                <p className="mt-2 text-sm text-gray-400 animate-fadeInBottom">Nossa IA Conversacional entende áudios e responde por áudios, possibilitando que você responda todos os seus clientes!</p>
              </div>
            </div>
          </div>

          <div className="relative col-span-6">
            {!animationSM ? <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <svg viewBox="0 0 558 558" width="558" height="558" fill="none" aria-hidden="true" className="animate-spin-slower">
                <defs>
                  <linearGradient id=":Rddaqlla:" x1="79" y1="16" x2="105" y2="237" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#13B5C8"></stop>
                    <stop offset="1" stopColor="#13B5C8" stopOpacity="0"></stop>
                  </linearGradient>
                </defs>
                <path opacity=".2" d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z" stroke="#13B5C8"></path>
                <path d="M1 279C1 125.465 125.465 1 279 1" stroke="url(#:Rddaqlla:)" strokeLinecap="round"></path>
              </svg>
            </div> : null}

            <div className={`relative aspect-[366/729] z-10 mx-auto w-full max-w-[366px]`}>
              <div>
                {screens}
              </div>

              <svg viewBox="0 0 366 729" aria-hidden="true" className="pointer-events-none absolute inset-0 h-full w-full fill-gray-100">
                <path fill="#F2F2F2" fillRule="evenodd" clipRule="evenodd" d="M300.092 1c41.22 0 63.223 21.99 63.223 63.213V184.94c-.173.184-.329.476-.458.851.188-.282.404-.547.647-.791.844-.073 2.496.257 2.496 2.157V268.719c-.406 2.023-2.605 2.023-2.605 2.023a7.119 7.119 0 0 1-.08-.102v394.462c0 41.213-22.001 63.212-63.223 63.212h-95.074c-.881-.468-2.474-.795-4.323-.838l-33.704-.005-.049.001h-.231l-.141-.001c-2.028 0-3.798.339-4.745.843H66.751c-41.223 0-63.223-21.995-63.223-63.208V287.739c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 284.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-16.289c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 214.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-26.041a2.26 2.26 0 0 0 .093-.236l-.064-.01a3.337 3.337 0 0 1-.72-.12l-.166-.028A2 2 0 0 1 1 135.62v-24.611a2 2 0 0 1 1.671-1.973l.857-.143v-44.68C3.528 22.99 25.53 1 66.75 1h233.341ZM3.952 234.516a5.481 5.481 0 0 0-.229-.278c.082.071.159.163.228.278Zm89.99-206.304A4.213 4.213 0 0 0 89.727 24H56.864C38.714 24 24 38.708 24 56.852v618.296C24 693.292 38.714 708 56.864 708h250.272c18.15 0 32.864-14.708 32.864-32.852V56.852C340 38.708 325.286 24 307.136 24h-32.864a4.212 4.212 0 0 0-4.213 4.212v2.527c0 10.235-8.3 18.532-18.539 18.532H112.48c-10.239 0-18.539-8.297-18.539-18.532v-2.527Z"></path>
                <rect x="154" y="29" width="56" height="5" rx="2.5" fill="#D4D4D4"></rect>
              </svg>
              <img alt="" loading="lazy" width="366" height="729" decoding="async" data-nimg="1" className="pointer-events-none absolute inset-0 h-full w-full" style={{ "color": "transparent" }} src="/assets/images/phone-frame.d4b6b62a.svg" />
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default Second;