import React, { useEffect, useState } from 'react';

type MessageProps = {
  type: 'user' | 'receiver';
  text?: string;
  userImage: string;
  userBgColor?: string;
  textColor?: string;
  children?: React.ReactNode;
};

const TextMessage: React.FC<MessageProps> = ({ type, text, userImage, userBgColor = 'bg-[#E2F7CB]', textColor = 'black', children }) => {
  const alignment = type === 'user' ? 'chat-end' : 'chat-start';
  const bgColor = type === 'user' ? userBgColor : 'bg-gray-300';
  const textColorUser = type === 'user' ? `text-${textColor}` : 'text-black';

  const [formattedTime, setFormattedTime] = useState<string>('');

  useEffect(() => {
    const now = new Date();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    setFormattedTime(`${hours}:${minutes}`);
  }, [])

  return (
    <div className={`chat ${alignment}`}>
      <div className='chat-header'>
        {type === 'receiver' && (
          <span className='text-xs opacity-50'>Assistente - </span>
        )}
        <time className="text-xs opacity-50">{formattedTime}</time>
      </div>
      <div className='chat-image avatar online'>
        <div className='h-8 w-8 min-w-[2rem] rounded-full'>
          {type === 'receiver' ? <img src="/assets/images/conversation/bot-profile.png" alt="ai-profile" /> : <img src={`/assets/images/conversation/${userImage}`} alt="user-profile" />}
        </div>
      </div>
      <div className={`p-3 chat-bubble ${bgColor} rounded-lg ${alignment} animate-fadeIn ${textColorUser} text-xs font-normal shadow-lg`}>
        {text ? <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }} /> : children }
      </div>
      <div className="chat-footer">
        {type === 'receiver' ? <span className='text-xs opacity-50'>Delivered</span> : <span className='text-xs opacity-50'>Seen</span>}
      </div>
    </div>
  );
};

export default TextMessage;