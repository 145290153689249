import Contact from "../../../Contact";

import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";

const ChatspaceLanding = () => {
  return (
    <>
      <First />
      <Second />
      <Third />
      <Fourth />
      <Contact />
    </>
  )
}

export default ChatspaceLanding;
