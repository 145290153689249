import React, { useState, Fragment } from "react";
import { toast } from "react-toastify";
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { LoadingDots } from "../components/LoadingDots";
import { toastErrors } from "../utils/toastErrors";

type listT = {
  id: number,
  content: string
}

const categories: listT[] = [
  { id: 1, content: 'Vendas' },
  { id: 2, content: 'Suporte' },
  { id: 3, content: 'Informações sobre sua empresa (FAQ)' },
  { id: 4, content: 'Outros' },
]

const expectedMessages: listT[] = [
  { id: 1, content: 'Não sei informar' },
  { id: 2, content: 'Até 100.000' },
  { id: 3, content: 'Entre 100.000 e 500.000' },
  { id: 4, content: 'Entre 500.000 e 1.000.000' },
  { id: 5, content: 'Acima de 1.000.000' },
]

const Contact = () => {
  const [name, setName] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<listT>();
  const [selectedExpectedMessages, setSelectedExpectedMessages] = useState<listT>();

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const formatPhoneNumber = (value: string): string => {
    const cleaned = ('' + value).replace(/\D/g, '');

    if (cleaned.length <= 2) {
      return `(${cleaned}`;
    } else if (cleaned.length <= 6) {
      return `(${cleaned.substring(0, 2)}) ${cleaned.substring(2)}`;
    } else if (cleaned.length <= 10) {
      return `(${cleaned.substring(0, 2)}) ${cleaned.substring(2, 6)}-${cleaned.substring(6)}`;
    } else if (cleaned.length <= 11) {
      return `(${cleaned.substring(0, 2)}) ${cleaned[2]} ${cleaned.substring(3, 7)}-${cleaned.substring(7)}`;
    }

    return value;
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const formattedValue = formatPhoneNumber(value);
    setPhoneNumber(formattedValue);
  };

  const isValidEmail = (email: string): boolean => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const handleForm = async () => {
    const toastId = toast.loading("Aguarde...");
    setLoading(true);

    if (!isValidEmail(email)) {
      toastErrors(toastId, { message: 'Email inválido!' });
      setLoading(false);
      return;
    }

    if (!name || !email || !phoneNumber || !selectedExpectedMessages || !selectedCategory) {
      toastErrors(toastId, { message: 'Preencha todos os campos!' });
      setLoading(false);
      return;
    }

    const data = {
      name: name,
      company: company,
      email: email,
      phoneNumber: phoneNumber,
      expectedMessages: selectedExpectedMessages?.content,
      category: selectedCategory?.content
    };

    try {
      await fetch('https://script.google.com/macros/s/AKfycbwMGftYjuzYp8cVN58FzJRAQOF9ncRTP32Yzmtm8xVul7kLBb3fVn5TwQW9QAjBLxi3/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      toast.update(toastId, {
        render: 'Agradecemos o contato! Retornaremos em breve.',
        type: "success",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      toastErrors(toastId, error);
      setLoading(false);
    }
  }

  return (
    <>
      <section id="contact" className="bg-white py-20 mx-auto max-w-7xl px-4 sm:px-6 lg:px-12 lg:py-[120px] overflow-hidden relative z-10">
        <div className="container">
          <div className="flex flex-wrap -mx-4 lg:justify-between">
            <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
              <div className="mb-12 max-w-[570px] lg:mb-0">
                <span className="block mb-4 text-base font-semibold text-[#2c2c50]">
                  Contate-nos
                </span>
                <h2 className="mb-6 text-[20px] font-bold uppercase text-dark sm:text-[18px] lg:text-[20px] xl:text-[24px]">
                  Quer entender como iremos inovar seu negócio com nossas soluções de IA Conversacional?
                </h2>
                <p className="text-base leading-relaxed mb-9 text-body-color">
                  Preencha o formulário ao lado e nosso time irá entrar em contato com você para entender suas necessidades e fazer uma demonstração das nossas soluções. Estamos ansiosos para fazer parte da sua <span className="font-semibold">jornada de sucesso!</span>
                </p>
                <div className="mb-8 flex w-full max-w-[370px] md:mt-16">
                  <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-[#2c2c50] bg-opacity-5 text-[#11111f] sm:h-[70px] sm:max-w-[70px]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={32} height={32} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                    </svg>
                  </div>
                  <div className="w-full flex flex-col justify-center">
                    <h4 className="mb-1 text-[17px] font-bold text-dark">
                      Customização à sua maneira!
                    </h4>
                    <p className="text-base text-body-color">
                      Soluções personalizadas para o seu negócio.
                    </p>
                  </div>
                </div>
                <div className="mb-8 flex w-full max-w-[370px]">
                  <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-[#2c2c50] bg-opacity-5 text-[#11111f] sm:h-[70px] sm:max-w-[70px]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={32} height={32} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                    </svg>
                  </div>
                  <div className="w-full flex flex-col justify-center">
                    <h4 className="mb-1 text-[17px] font-bold text-dark">
                      Atendimento 24/7!
                    </h4>
                    <p className="text-base text-body-color">
                      Disponibilidade ininterrupta para seus clientes/colaboradores.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="relative p-8 bg-white rounded-lg shadow-lg sm:p-12">
                <>
                  <ContactInputBox
                    type="text"
                    name="name"
                    placeholder="Seu nome completo"
                    onChange={(e: any) => setName(e.target.value)}
                    value={!name ? '' : name}
                  />
                  <ContactInputBox
                    type="text"
                    name="name"
                    placeholder="Nome da empresa"
                    onChange={(e: any) => setCompany(e.target.value)}
                    value={!company ? '' : company}
                  />
                  <ContactInputBox
                    type="text"
                    name="email"
                    placeholder="Email comercial"
                    onChange={(e: any) => setEmail(e.target.value)}
                    value={!email ? '' : email}
                  />
                  <ContactInputBox
                    type="text"
                    name="phone"
                    placeholder="Telefone de contato"
                    maxLength={16}
                    onChange={handlePhoneNumberChange}
                    value={!phoneNumber ? '' : phoneNumber}
                  />
                  <div className="relative z-20">
                    <ListInputBox list={expectedMessages} placeholder="Expectativa de mensagens mensais" selected={selectedExpectedMessages} setSelected={setSelectedExpectedMessages} />
                  </div>
                  <div className="relative z-10">
                    <ListInputBox list={categories} placeholder="Como podemos te ajudar?" selected={selectedCategory} setSelected={setSelectedCategory} />
                  </div>
                  <div>
                    <button
                      type="submit"
                      onClick={handleForm}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      className="w-full p-3 transition border rounded border-[#2c2c50] text-[#2c2c50] bg-white hover:bg-opacity-90 hover:bg-[#2c2c50] hover:text-white"
                    >
                      {loading ? <LoadingDots color={isHovered ? "white" : "black"} dotStyle="small" /> : 'Enviar mensagem'}
                    </button>
                  </div>
                </>
                <div>
                  <span className="absolute -top-10 -right-9 z-[-1]">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                        fill="#2c2c50"
                      />
                    </svg>
                  </span>
                  <span className="absolute -right-10 top-[90px] z-[-1]">
                    <svg
                      width={34}
                      height={134}
                      viewBox="0 0 34 134"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="31.9993" cy={132} r="1.66667" transform="rotate(180 31.9993 132)" fill="#11111f" />
                      <circle cx="31.9993" cy="117.333" r="1.66667" transform="rotate(180 31.9993 117.333)" fill="#11111f" />
                      <circle cx="31.9993" cy="102.667" r="1.66667" transform="rotate(180 31.9993 102.667)" fill="#11111f" />
                      <circle cx="31.9993" cy={88} r="1.66667" transform="rotate(180 31.9993 88)" fill="#11111f" />
                      <circle cx="31.9993" cy="73.3333" r="1.66667" transform="rotate(180 31.9993 73.3333)" fill="#11111f" />
                      <circle cx="31.9993" cy={45} r="1.66667" transform="rotate(180 31.9993 45)" fill="#11111f" />
                      <circle cx="31.9993" cy={16} r="1.66667" transform="rotate(180 31.9993 16)" fill="#11111f" />
                      <circle cx="31.9993" cy={59} r="1.66667" transform="rotate(180 31.9993 59)" fill="#11111f" />
                      <circle cx="31.9993" cy="30.6666" r="1.66667" transform="rotate(180 31.9993 30.6666)" fill="#11111f" />
                      <circle cx="31.9993" cy="1.66665" r="1.66667" transform="rotate(180 31.9993 1.66665)" fill="#11111f" />
                      <circle cx="17.3333" cy={132} r="1.66667" transform="rotate(180 17.3333 132)" fill="#11111f" />
                      <circle cx="17.3333" cy="117.333" r="1.66667" transform="rotate(180 17.3333 117.333)" fill="#11111f" />
                      <circle cx="17.3333" cy="102.667" r="1.66667" transform="rotate(180 17.3333 102.667)" fill="#11111f" />
                      <circle cx="17.3333" cy={88} r="1.66667" transform="rotate(180 17.3333 88)" fill="#11111f" />
                      <circle cx="17.3333" cy="73.3333" r="1.66667" transform="rotate(180 17.3333 73.3333)" fill="#11111f" />
                      <circle cx="17.3333" cy={45} r="1.66667" transform="rotate(180 17.3333 45)" fill="#11111f" />
                      <circle cx="17.3333" cy={16} r="1.66667" transform="rotate(180 17.3333 16)" fill="#11111f" />
                      <circle cx="17.3333" cy={59} r="1.66667" transform="rotate(180 17.3333 59)" fill="#11111f" />
                      <circle cx="17.3333" cy="30.6666" r="1.66667" transform="rotate(180 17.3333 30.6666)" fill="#11111f" />
                      <circle cx="17.3333" cy="1.66665" r="1.66667" transform="rotate(180 17.3333 1.66665)" fill="#11111f" />
                      <circle cx="2.66536" cy={132} r="1.66667" transform="rotate(180 2.66536 132)" fill="#11111f" />
                      <circle cx="2.66536" cy="117.333" r="1.66667" transform="rotate(180 2.66536 117.333)" fill="#11111f" />
                      <circle cx="2.66536" cy="102.667" r="1.66667" transform="rotate(180 2.66536 102.667)" fill="#11111f" />
                      <circle cx="2.66536" cy={88} r="1.66667" transform="rotate(180 2.66536 88)" fill="#11111f" />
                      <circle cx="2.66536" cy="73.3333" r="1.66667" transform="rotate(180 2.66536 73.3333)" fill="#11111f" />
                      <circle cx="2.66536" cy={45} r="1.66667" transform="rotate(180 2.66536 45)" fill="#11111f" />
                      <circle cx="2.66536" cy={16} r="1.66667" transform="rotate(180 2.66536 16)" fill="#11111f" />
                      <circle cx="2.66536" cy={59} r="1.66667" transform="rotate(180 2.66536 59)" fill="#11111f" />
                      <circle cx="2.66536" cy="30.6666" r="1.66667" transform="rotate(180 2.66536 30.6666)" fill="#11111f" />
                      <circle cx="2.66536" cy="1.66665" r="1.66667" transform="rotate(180 2.66536 1.66665)" fill="#11111f" />
                    </svg>
                  </span>
                  <span className="absolute -left-7 -bottom-7 z-[-1]">
                    <svg
                      width={107}
                      height={134}
                      viewBox="0 0 107 134"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="104.999" cy={132} r="1.66667" transform="rotate(180 104.999 132)" fill="#11111f" />
                      <circle cx="104.999" cy="117.333" r="1.66667" transform="rotate(180 104.999 117.333)" fill="#11111f" />
                      <circle cx="104.999" cy="102.667" r="1.66667" transform="rotate(180 104.999 102.667)" fill="#11111f" />
                      <circle cx="104.999" cy={88} r="1.66667" transform="rotate(180 104.999 88)" fill="#11111f" />
                      <circle cx="104.999" cy="73.3333" r="1.66667" transform="rotate(180 104.999 73.3333)" fill="#11111f" />
                      <circle cx="104.999" cy={45} r="1.66667" transform="rotate(180 104.999 45)" fill="#11111f" />
                      <circle cx="104.999" cy={16} r="1.66667" transform="rotate(180 104.999 16)" fill="#11111f" />
                      <circle cx="104.999" cy={59} r="1.66667" transform="rotate(180 104.999 59)" fill="#11111f" />
                      <circle cx="104.999" cy="30.6666" r="1.66667" transform="rotate(180 104.999 30.6666)" fill="#11111f" />
                      <circle cx="104.999" cy="1.66665" r="1.66667" transform="rotate(180 104.999 1.66665)" fill="#11111f" />
                      <circle cx="90.3333" cy={132} r="1.66667" transform="rotate(180 90.3333 132)" fill="#11111f" />
                      <circle cx="90.3333" cy="117.333" r="1.66667" transform="rotate(180 90.3333 117.333)" fill="#11111f" />
                      <circle cx="90.3333" cy="102.667" r="1.66667" transform="rotate(180 90.3333 102.667)" fill="#11111f" />
                      <circle cx="90.3333" cy={88} r="1.66667" transform="rotate(180 90.3333 88)" fill="#11111f" />
                      <circle cx="90.3333" cy="73.3333" r="1.66667" transform="rotate(180 90.3333 73.3333)" fill="#11111f" />
                      <circle cx="90.3333" cy={45} r="1.66667" transform="rotate(180 90.3333 45)" fill="#11111f" />
                      <circle cx="90.3333" cy={16} r="1.66667" transform="rotate(180 90.3333 16)" fill="#11111f" />
                      <circle cx="90.3333" cy={59} r="1.66667" transform="rotate(180 90.3333 59)" fill="#11111f" />
                      <circle cx="90.3333" cy="30.6666" r="1.66667" transform="rotate(180 90.3333 30.6666)" fill="#11111f" />
                      <circle cx="90.3333" cy="1.66665" r="1.66667" transform="rotate(180 90.3333 1.66665)" fill="#11111f" />
                      <circle cx="75.6654" cy={132} r="1.66667" transform="rotate(180 75.6654 132)" fill="#11111f" />
                      <circle cx="31.9993" cy={132} r="1.66667" transform="rotate(180 31.9993 132)" fill="#11111f" />
                      <circle cx="75.6654" cy="117.333" r="1.66667" transform="rotate(180 75.6654 117.333)" fill="#11111f" />
                      <circle cx="31.9993" cy="117.333" r="1.66667" transform="rotate(180 31.9993 117.333)" fill="#11111f" />
                      <circle cx="75.6654" cy="102.667" r="1.66667" transform="rotate(180 75.6654 102.667)" fill="#11111f" />
                      <circle cx="31.9993" cy="102.667" r="1.66667" transform="rotate(180 31.9993 102.667)" fill="#11111f" />
                      <circle cx="75.6654" cy={88} r="1.66667" transform="rotate(180 75.6654 88)" fill="#11111f" />
                      <circle cx="31.9993" cy={88} r="1.66667" transform="rotate(180 31.9993 88)" fill="#11111f" />
                      <circle cx="75.6654" cy="73.3333" r="1.66667" transform="rotate(180 75.6654 73.3333)" fill="#11111f" />
                      <circle cx="31.9993" cy="73.3333" r="1.66667" transform="rotate(180 31.9993 73.3333)" fill="#11111f" />
                      <circle cx="75.6654" cy={45} r="1.66667" transform="rotate(180 75.6654 45)" fill="#11111f" />
                      <circle cx="31.9993" cy={45} r="1.66667" transform="rotate(180 31.9993 45)" fill="#11111f" />
                      <circle cx="75.6654" cy={16} r="1.66667" transform="rotate(180 75.6654 16)" fill="#11111f" />
                      <circle cx="31.9993" cy={16} r="1.66667" transform="rotate(180 31.9993 16)" fill="#11111f" />
                      <circle cx="75.6654" cy={59} r="1.66667" transform="rotate(180 75.6654 59)" fill="#11111f" />
                      <circle cx="31.9993" cy={59} r="1.66667" transform="rotate(180 31.9993 59)" fill="#11111f" />
                      <circle cx="75.6654" cy="30.6666" r="1.66667" transform="rotate(180 75.6654 30.6666)" fill="#11111f" />
                      <circle cx="31.9993" cy="30.6666" r="1.66667" transform="rotate(180 31.9993 30.6666)" fill="#11111f" />
                      <circle cx="75.6654" cy="1.66665" r="1.66667" transform="rotate(180 75.6654 1.66665)" fill="#11111f" />
                      <circle cx="31.9993" cy="1.66665" r="1.66667" transform="rotate(180 31.9993 1.66665)" fill="#11111f" />
                      <circle cx="60.9993" cy={132} r="1.66667" transform="rotate(180 60.9993 132)" fill="#11111f" />
                      <circle cx="17.3333" cy={132} r="1.66667" transform="rotate(180 17.3333 132)" fill="#11111f" />
                      <circle cx="60.9993" cy="117.333" r="1.66667" transform="rotate(180 60.9993 117.333)" fill="#11111f" />
                      <circle cx="17.3333" cy="117.333" r="1.66667" transform="rotate(180 17.3333 117.333)" fill="#11111f" />
                      <circle cx="60.9993" cy="102.667" r="1.66667" transform="rotate(180 60.9993 102.667)" fill="#11111f" />
                      <circle cx="17.3333" cy="102.667" r="1.66667" transform="rotate(180 17.3333 102.667)" fill="#11111f" />
                      <circle cx="60.9993" cy={88} r="1.66667" transform="rotate(180 60.9993 88)" fill="#11111f" />
                      <circle cx="17.3333" cy={88} r="1.66667" transform="rotate(180 17.3333 88)" fill="#11111f" />
                      <circle cx="60.9993" cy="73.3333" r="1.66667" transform="rotate(180 60.9993 73.3333)" fill="#11111f" />
                      <circle cx="17.3333" cy="73.3333" r="1.66667" transform="rotate(180 17.3333 73.3333)" fill="#11111f" />
                      <circle cx="60.9993" cy={45} r="1.66667" transform="rotate(180 60.9993 45)" fill="#11111f" />
                      <circle cx="17.3333" cy={45} r="1.66667" transform="rotate(180 17.3333 45)" fill="#11111f" />
                      <circle cx="60.9993" cy={16} r="1.66667" transform="rotate(180 60.9993 16)" fill="#11111f" />
                      <circle cx="17.3333" cy={16} r="1.66667" transform="rotate(180 17.3333 16)" fill="#11111f" />
                      <circle cx="60.9993" cy={59} r="1.66667" transform="rotate(180 60.9993 59)" fill="#11111f" />
                      <circle cx="17.3333" cy={59} r="1.66667" transform="rotate(180 17.3333 59)" fill="#11111f" />
                      <circle cx="60.9993" cy="30.6666" r="1.66667" transform="rotate(180 60.9993 30.6666)" fill="#11111f" />
                      <circle cx="17.3333" cy="30.6666" r="1.66667" transform="rotate(180 17.3333 30.6666)" fill="#11111f" />
                      <circle cx="60.9993" cy="1.66665" r="1.66667" transform="rotate(180 60.9993 1.66665)" fill="#11111f" />
                      <circle cx="17.3333" cy="1.66665" r="1.66667" transform="rotate(180 17.3333 1.66665)" fill="#11111f" />
                      <circle cx="46.3333" cy={132} r="1.66667" transform="rotate(180 46.3333 132)" fill="#11111f" />
                      <circle cx="2.66536" cy={132} r="1.66667" transform="rotate(180 2.66536 132)" fill="#11111f" />
                      <circle cx="46.3333" cy="117.333" r="1.66667" transform="rotate(180 46.3333 117.333)" fill="#11111f" />
                      <circle cx="2.66536" cy="117.333" r="1.66667" transform="rotate(180 2.66536 117.333)" fill="#11111f" />
                      <circle cx="46.3333" cy="102.667" r="1.66667" transform="rotate(180 46.3333 102.667)" fill="#11111f" />
                      <circle cx="2.66536" cy="102.667" r="1.66667" transform="rotate(180 2.66536 102.667)" fill="#11111f" />
                      <circle cx="46.3333" cy={88} r="1.66667" transform="rotate(180 46.3333 88)" fill="#11111f" />
                      <circle cx="2.66536" cy={88} r="1.66667" transform="rotate(180 2.66536 88)" fill="#11111f" />
                      <circle cx="46.3333" cy="73.3333" r="1.66667" transform="rotate(180 46.3333 73.3333)" fill="#11111f" />
                      <circle cx="2.66536" cy="73.3333" r="1.66667" transform="rotate(180 2.66536 73.3333)" fill="#11111f" />
                      <circle cx="46.3333" cy={45} r="1.66667" transform="rotate(180 46.3333 45)" fill="#11111f" />
                      <circle cx="2.66536" cy={45} r="1.66667" transform="rotate(180 2.66536 45)" fill="#11111f" />
                      <circle cx="46.3333" cy={16} r="1.66667" transform="rotate(180 46.3333 16)" fill="#11111f" />
                      <circle cx="2.66536" cy={16} r="1.66667" transform="rotate(180 2.66536 16)" fill="#11111f" />
                      <circle cx="46.3333" cy={59} r="1.66667" transform="rotate(180 46.3333 59)" fill="#11111f" />
                      <circle cx="2.66536" cy={59} r="1.66667" transform="rotate(180 2.66536 59)" fill="#11111f" />
                      <circle cx="46.3333" cy="30.6666" r="1.66667" transform="rotate(180 46.3333 30.6666)" fill="#11111f" />
                      <circle cx="2.66536" cy="30.6666" r="1.66667" transform="rotate(180 2.66536 30.6666)" fill="#11111f" />
                      <circle cx="46.3333" cy="1.66665" r="1.66667" transform="rotate(180 46.3333 1.66665)" fill="#11111f" />
                      <circle cx="2.66536" cy="1.66665" r="1.66667" transform="rotate(180 2.66536 1.66665)" fill="#11111f" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

const ContactTextArea = ({ row, placeholder, name, value, onChange }: any) => {
  return (
    <>
      <div className="mb-6">
        <textarea
          rows={row}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          className="border-[#f0f0f0] w-full resize-none rounded border py-3 px-[14px] text-base text-body-color outline-none focus:border-[#11111f] focus:ring-transparent focus-visible:shadow-none"
        />
      </div>
    </>
  );
};

const ContactInputBox = ({ type, placeholder, name, value, onChange, maxLength }: any) => {
  return (
    <>
      <div className="mb-6">
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          maxLength={maxLength}
          value={value}
          onChange={onChange}
          className="border-[#f0f0f0] w-full rounded border py-3 px-[14px] text-base text-body-color outline-none focus:border-[#11111f] focus:ring-transparent focus-visible:shadow-none"
        />
      </div>
    </>
  );
};

const ListInputBox = ({ list, placeholder, selected, setSelected }: any) => {
  return (
    <div className="mb-6">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="relative cursor-pointer border-[#f0f0f0] w-full rounded border bg-white py-3 px-[14px] text-left text-base text-body-color outline-none focus:border-[#11111f] focus:ring-transparent focus-visible:shadow-none sm:text-sm">
            <span className={`block truncate text-base ${selected?.content ? '' : 'text-gray-500'}`}>{selected?.content ? selected?.content : placeholder}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {list.map((item: listT, index: number) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'text-white bg-[#2c2c50]' : 'text-[#2c2c50]'}`
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                          }`}
                      >
                        {item.content}
                      </span>
                      {selected ? (
                        <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-[#2c2c50]'}`}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}