import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter, Link } from "react-router-dom";
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import avatar from './bot-profile.png';
import CookieConsent from "react-cookie-consent";

import './styles/index.css';

import { Header, Footer } from './components';
import App from './App';

import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
      <App />
      <CookieConsent
        location="bottom"
        buttonText="Aceitar"
        style={{ background: "#fff", zIndex: "10000", padding: "20px", fontSize: "14px", color: "#RB5563", alignItems: "center", boxShadow: "0 -1px 10px 0 #acabab4d" }}
        buttonStyle={{ background: "#11111f", color: "#fff", fontSize: "13px", padding: "10px 25px 10px", borderRadius: "5px" }}
        expires={150}
      >
        <p className="text-[#4b5563]">Para proporcionar a melhor experiência de navegação e personalizar o conteúdo que oferecemos, utilizamos cookies e serviços de terceiros em nosso site. Ao continuar navegando, você concorda com nossa Política de Privacidade. Para mais informações, consulte nossa <Link to="privacy" className="text-[#2c2c50] underline">Política de Privacidade</Link> ou entre em contato conosco através do e-mail contato@arkspace.ai.</p>
      </CookieConsent>
      <FloatingWhatsApp avatar={avatar} accountName='Assistente Arkspace' phoneNumber='+553140427164' chatMessage={`Olá! \nComo posso te ajudar? 😊`} statusMessage='' placeholder='Digite uma mensagem...' allowEsc={true} />
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
