import React from 'react';

type AudioMessageProps = {
  duration: string; // e.g., '0:30'
};

const AudioMessage: React.FC<AudioMessageProps> = ({ duration }) => {
  return (
    <div className="flex items-center rounded-lg">
      <div className="mr-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 fill-current text-green-500"
          viewBox="0 0 20 20"
        >
          <path d="M6 5L14 10L6 15V5Z"></path>
        </svg>
      </div>
      <div className="flex-grow bg-green-300 rounded-lg h-3 flex space-x-0.5">
        <div className="w-1 h-1/3 bg-green-500 self-end"></div>
        <div className="w-1 h-1/2 bg-green-500 self-end"></div>
        <div className="w-1 h-2/3 bg-green-500 self-end"></div>
        <div className="w-1 h-1/4 bg-green-500 self-end"></div>
        <div className="w-1 h-1/4 bg-green-500 self-end"></div>
        <div className="w-1 h-1/2 bg-green-500 self-end"></div>
        <div className="w-1 h-1/3 bg-green-500 self-end"></div>
        <div className="w-1 h-1/3 bg-green-500 self-end"></div>
        <div className="w-1 h-2/3 bg-green-500 self-end"></div>
        <div className="w-1 h-1/2 bg-green-500 self-end"></div>
      </div>
      <span className="ml-2 text-sm">{duration}</span>
    </div>
  );
};

export default AudioMessage;
