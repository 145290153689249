import { smoothScrollTo } from "../../../../utils/smoothScroll";

const Fourth = () => {
  return (
    <section id="by-demand" className="relative overflow-hidden bg-[#11111f] py-20 sm:py-28">
      <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
        <svg viewBox="0 0 558 558" width="558" height="558" fill="none" aria-hidden="true" className="animate-spin-slower">
          <defs>
            <linearGradient id=":S4:" x1="79" y1="16" x2="105" y2="237" gradientUnits="userSpaceOnUse">
              <stop stopColor="#fff"></stop>
              <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
            </linearGradient>
          </defs>
          <path opacity=".2" d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z" stroke="#fff"></path>
          <path d="M1 279C1 125.465 125.465 1 279 1" stroke="url(#:S4:)" strokeLinecap="round"></path>
        </svg>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div className="mx-auto max-w-md sm:text-center">
          <h2 className="text-3xl font-medium tracking-tight text-white sm:text-3xl">Quer ver como nossas soluções funcionam na prática?</h2>
          <p className="mt-4 text-md text-gray-300">Nosso time de experts irá mostrar na prática como podemos te ajudar a escalar seu negócio.</p>
          <div className="mt-8 flex justify-center">
            <button onClick={() => smoothScrollTo('contact')} className="inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors border-white text-white hover:bg-white hover:text-[#2c2c50] hover:border-white">
              <span>Entre em contato com nosso time</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Fourth;
