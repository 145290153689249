import { Link } from "react-router-dom";
import { smoothScrollTo } from "../utils/smoothScroll";

const Footer = () => {
  return (
    <footer className="pt-20 mx-auto w-full px-4 sm:px-6 lg:px-12 lg:pt-[120px] overflow-hidden relative z-10 bg-[#11111f]">
      <div className="container">
        <div className="flex flex-wrap">
          <div className="w-full px-4 sm:w-1/2 md:w-1/2 lg:w-4/12 xl:w-3/12">
            <div className="mb-10 w-full">
              <a href="/" className="-ml-2 mb-5 mt-10 md:-mt-2 inline-block max-w-[250px]">
                <img src="/assets/images/brand/brand-image-p-500-white.png" alt="logo" className="max-w-full" />
              </a>
              <p className="mb-7 text-base text-[#f3f4fe]">
                Criamos soluções de inteligência artificial para impulsionar empresas inovadoras.
              </p>
              <div className="-mx-3 flex items-center">
                <a href="http://www.twitter.com/arkspaceai?s=21&t=KBQx8BMCLd2TuP4jvdo2qA" rel="noreferrer nofollow noopner" target="_blank" className="px-3 text-[#dddddd] hover:text-white">
                  <svg width="19" height="15" viewBox="0 0 19 15" className="fill-current">
                    <path d="M16.2622 3.17878L17.33 1.93293C17.6391 1.59551 17.7234 1.33595 17.7515 1.20618C16.9085 1.67337 16.1217 1.82911 15.6159 1.82911H15.4192L15.3068 1.72528C14.6324 1.18022 13.7894 0.894714 12.8902 0.894714C10.9233 0.894714 9.37779 2.40012 9.37779 4.13913C9.37779 4.24295 9.37779 4.39868 9.40589 4.5025L9.49019 5.02161L8.90009 4.99565C5.30334 4.89183 2.35288 2.03675 1.87518 1.5436C1.08839 2.84136 1.53799 4.08722 2.01568 4.86587L2.97107 6.31937L1.45369 5.54071C1.48179 6.63084 1.93138 7.48736 2.80247 8.11029L3.56116 8.62939L2.80247 8.9149C3.28017 10.2386 4.34795 10.7837 5.13474 10.9913L6.17443 11.2509L5.19094 11.8738C3.61736 12.912 1.65039 12.8342 0.779297 12.7563C2.54957 13.8983 4.65705 14.1579 6.11823 14.1579C7.21412 14.1579 8.02901 14.0541 8.2257 13.9762C16.0936 12.2631 16.4589 5.77431 16.4589 4.47655V4.29486L16.6275 4.19104C17.5829 3.36047 17.9763 2.91923 18.2011 2.65967C18.1168 2.68563 18.0044 2.73754 17.892 2.7635L16.2622 3.17878Z"></path>
                  </svg>
                </a>
                <a href="https://www.instagram.com/arkspace.ai" rel="noreferrer nofollow noopner" target="_blank" className="px-3 text-[#dddddd] hover:text-white">
                  <svg width="18" height="18" viewBox="0 0 18 18" className="fill-current">
                    <path d="M8.91688 12.4995C10.6918 12.4995 12.1306 11.0911 12.1306 9.35385C12.1306 7.61655 10.6918 6.20819 8.91688 6.20819C7.14197 6.20819 5.70312 7.61655 5.70312 9.35385C5.70312 11.0911 7.14197 12.4995 8.91688 12.4995Z"></path>
                    <path d="M12.4078 0.947388H5.37075C2.57257 0.947388 0.300781 3.17104 0.300781 5.90993V12.7436C0.300781 15.5367 2.57257 17.7604 5.37075 17.7604H12.3524C15.2059 17.7604 17.4777 15.5367 17.4777 12.7978V5.90993C17.4777 3.17104 15.2059 0.947388 12.4078 0.947388ZM8.91696 13.4758C6.56206 13.4758 4.70584 11.6047 4.70584 9.35389C4.70584 7.10312 6.58976 5.23199 8.91696 5.23199C11.2165 5.23199 13.1004 7.10312 13.1004 9.35389C13.1004 11.6047 11.2442 13.4758 8.91696 13.4758ZM14.735 5.61164C14.4579 5.90993 14.0423 6.07264 13.5714 6.07264C13.1558 6.07264 12.7402 5.90993 12.4078 5.61164C12.103 5.31334 11.9368 4.9337 11.9368 4.47269C11.9368 4.01169 12.103 3.65916 12.4078 3.33375C12.7125 3.00834 13.1004 2.84563 13.5714 2.84563C13.9869 2.84563 14.4302 3.00834 14.735 3.30663C15.012 3.65916 15.2059 4.06593 15.2059 4.49981C15.1782 4.9337 15.012 5.31334 14.735 5.61164Z"></path>
                    <path d="M13.5985 3.82184C13.2383 3.82184 12.9336 4.12013 12.9336 4.47266C12.9336 4.82519 13.2383 5.12349 13.5985 5.12349C13.9587 5.12349 14.2634 4.82519 14.2634 4.47266C14.2634 4.12013 13.9864 3.82184 13.5985 3.82184Z"></path>
                  </svg>
                </a>
                <a href="https://www.linkedin.com/company/arkspaceai" rel="noreferrer nofollow noopner" target="_blank" className="px-3 text-[#dddddd] hover:text-white">
                  <svg width="18" height="18" viewBox="0 0 18 18" className="fill-current">
                    <path d="M16.7821 0.947388H1.84847C1.14272 0.947388 0.578125 1.49747 0.578125 2.18508V16.7623C0.578125 17.4224 1.14272 18 1.84847 18H16.7257C17.4314 18 17.996 17.4499 17.996 16.7623V2.15757C18.0525 1.49747 17.4879 0.947388 16.7821 0.947388ZM5.7442 15.4421H3.17528V7.32837H5.7442V15.4421ZM4.44563 6.2007C3.59873 6.2007 2.94944 5.5406 2.94944 4.74297C2.94944 3.94535 3.62696 3.28525 4.44563 3.28525C5.26429 3.28525 5.94181 3.94535 5.94181 4.74297C5.94181 5.5406 5.32075 6.2007 4.44563 6.2007ZM15.4835 15.4421H12.9146V11.509C12.9146 10.5739 12.8864 9.33618 11.5596 9.33618C10.2045 9.33618 10.0069 10.3813 10.0069 11.4265V15.4421H7.438V7.32837H9.95046V8.45605H9.9787C10.3457 7.79594 11.1644 7.13584 12.4347 7.13584C15.0601 7.13584 15.54 8.7861 15.54 11.0414V15.4421H15.4835Z"></path>
                  </svg>
                </a>
                <a href="https://wa.me/553140427164" rel="noreferrer nofollow noopner" target="_blank" className="px-3 text-[#dddddd] hover:text-white">
                  <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 308 308" xmlSpace="preserve" className="fill-current">
                    <g id="XMLID_468_">
                      <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                      <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="w-full px-4 sm:w-1/2 md:w-1/2 lg:w-3/12 xl:w-2/12">
            <div className="mb-10 w-full">
              <h4 className="mb-9 text-lg font-semibold text-white sm:mt-12 md:mt-0">Sobre a Arkspace</h4>
              <ul>
                <li>
                  <a href="/" className="mb-2 inline-block text-base leading-loose text-[#f3f4fe] hover:text-white">
                    Home
                  </a>
                </li>
                <li>
                  <button onClick={() => smoothScrollTo('solutions')} className="mb-2 inline-block text-base leading-loose text-[#f3f4fe] hover:text-white">
                    Soluções
                  </button>
                </li>
                <li>
                  <button onClick={() => smoothScrollTo('by-demand')} className="mb-2 inline-block text-base leading-loose text-[#f3f4fe] hover:text-white">
                    Sob encomenda
                  </button>
                </li>
                {/* <li>
                  <a href="/" className="mb-2 inline-block text-base leading-loose text-[#f3f4fe] hover:text-white">
                    Cases
                  </a>
                </li>
                <li>
                  <a href="/" className="mb-2 inline-block text-base leading-loose text-[#f3f4fe] hover:text-white">
                    Sobre nós
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          {/* <div className="w-full px-4 sm:w-1/2 md:w-1/2 lg:w-3/12 xl:w-2/12">
            <div className="mb-10 w-full">
              <h4 className="mb-9 text-lg font-semibold text-white">
                Produtos
              </h4>
              <ul>
                <li>
                  <button onClick={() => smoothScrollTo('product')} className="mb-2 inline-block text-base leading-loose text-[#f3f4fe] hover:text-white">
                    Chatspace
                  </button>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="w-full px-4 md:w-2/3 lg:w-5/12 xl:w-3/12">
            <div className="mb-10 w-full">
              <h4 className="mb-9 text-lg font-semibold text-white">Parceiros</h4>
              <ul className="flex flex-wrap items-center">
                <li>
                  <a href="https://www.squadra.com.br/" rel="noreferrer nofollow noopner" target="_blank" className="mb-6 mr-5 block max-w-[120px] xl:max-w-[100px] 2xl:max-w-[120px]">
                    <img src="assets/images/footer/brands/squadra-logo-white.png" alt="squadra" />
                  </a>
                </li>
                <li>
                  <a href="https://readyforproduction.dev/" rel="noreferrer nofollow noopner" target="_blank" className="mb-6 mr-5 block max-w-[120px] xl:max-w-[100px] 2xl:max-w-[120px]">
                    <img src="assets/images/footer/brands/ready-for-production.png" alt="ready-for-production" />
                  </a>
                </li>
                {/* <li>
                  <a href="https://sambatech.com/" rel="noreferrer nofollow noopner" target="_blank" className="mb-6 mr-5 block max-w-[120px] xl:max-w-[100px] 2xl:max-w-[120px]">
                    <img src="assets/images/footer/brands/sambatech-logo.png" alt="sambatech" />
                  </a>
                </li>
                <li className="w-[140px]">
                  <a href="https://octovb.com/" rel="noreferrer nofollow noopner" target="_blank" className="flex justify-center mb-6 mr-5 block max-w-[120px] xl:max-w-[100px] 2xl:max-w-[120px]">
                    <img className="w-[3vh]" src="assets/images/footer/brands/octo-logo.png" alt="octo" />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 border-t border-opacity-40 py-8 lg:mt-[60px]">
        <div className="w-full">
          <div className="flex flex-wrap">
            <div className="w-full px-4 md:w-2/3 lg:w-1/2">
              <div className="my-1">
                <div className="-mx-3 flex items-center justify-center md:justify-start">
                  <Link to="/privacy" className="px-3 md:px-0 text-sm text-[#f3f4fe] hover:text-white">
                    Políticas de privacidade
                  </Link>
                </div>
              </div>
            </div>
            <div className="w-full pt-4 px-4 md:pt-0 md:px-0 md:w-1/3 lg:w-1/2">
              <div className="my-1 flex justify-center md:justify-end">
                <p className="text-[#f3f4fe] text-sm">
                  ©2023 Arkspace Consultoria e Desenvolvimento de Software LTDA. 51.535.723/0001-26
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <span className="absolute left-0 top-0 z-[-1]">
          <img src="assets/images/footer/shape-1.svg" alt="" />
        </span>

        <span className="absolute bottom-0 right-0 z-[-1]">
          <img src="assets/images/footer/shape-3.svg" alt="" />
        </span>

        <span className="absolute right-0 -top-[20px] z-[-1]">
          <svg
            width={107}
            height={134}
            viewBox="0 0 107 134"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="104.999" cy={132} r="1.66667" transform="rotate(180 104.999 132)" fill="#ffffff" />
            <circle cx="104.999" cy="117.333" r="1.66667" transform="rotate(180 104.999 117.333)" fill="#ffffff" />
            <circle cx="104.999" cy="102.667" r="1.66667" transform="rotate(180 104.999 102.667)" fill="#ffffff" />
            <circle cx="104.999" cy={88} r="1.66667" transform="rotate(180 104.999 88)" fill="#ffffff" />
            <circle cx="104.999" cy="73.3333" r="1.66667" transform="rotate(180 104.999 73.3333)" fill="#ffffff" />
            <circle cx="104.999" cy={45} r="1.66667" transform="rotate(180 104.999 45)" fill="#ffffff" />
            <circle cx="104.999" cy={16} r="1.66667" transform="rotate(180 104.999 16)" fill="#ffffff" />
            <circle cx="104.999" cy={59} r="1.66667" transform="rotate(180 104.999 59)" fill="#ffffff" />
            <circle cx="104.999" cy="30.6666" r="1.66667" transform="rotate(180 104.999 30.6666)" fill="#ffffff" />
            <circle cx="104.999" cy="1.66665" r="1.66667" transform="rotate(180 104.999 1.66665)" fill="#ffffff" />
            <circle cx="90.3333" cy={132} r="1.66667" transform="rotate(180 90.3333 132)" fill="#ffffff" />
            <circle cx="90.3333" cy="117.333" r="1.66667" transform="rotate(180 90.3333 117.333)" fill="#ffffff" />
            <circle cx="90.3333" cy="102.667" r="1.66667" transform="rotate(180 90.3333 102.667)" fill="#ffffff" />
            <circle cx="90.3333" cy={88} r="1.66667" transform="rotate(180 90.3333 88)" fill="#ffffff" />
            <circle cx="90.3333" cy="73.3333" r="1.66667" transform="rotate(180 90.3333 73.3333)" fill="#ffffff" />
            <circle cx="90.3333" cy={45} r="1.66667" transform="rotate(180 90.3333 45)" fill="#ffffff" />
            <circle cx="90.3333" cy={16} r="1.66667" transform="rotate(180 90.3333 16)" fill="#ffffff" />
            <circle cx="90.3333" cy={59} r="1.66667" transform="rotate(180 90.3333 59)" fill="#ffffff" />
            <circle cx="90.3333" cy="30.6666" r="1.66667" transform="rotate(180 90.3333 30.6666)" fill="#ffffff" />
            <circle cx="90.3333" cy="1.66665" r="1.66667" transform="rotate(180 90.3333 1.66665)" fill="#ffffff" />
            <circle cx="75.6654" cy={132} r="1.66667" transform="rotate(180 75.6654 132)" fill="#ffffff" />
            <circle cx="31.9993" cy={132} r="1.66667" transform="rotate(180 31.9993 132)" fill="#ffffff" />
            <circle cx="75.6654" cy="117.333" r="1.66667" transform="rotate(180 75.6654 117.333)" fill="#ffffff" />
            <circle cx="31.9993" cy="117.333" r="1.66667" transform="rotate(180 31.9993 117.333)" fill="#ffffff" />
            <circle cx="75.6654" cy="102.667" r="1.66667" transform="rotate(180 75.6654 102.667)" fill="#ffffff" />
            <circle cx="31.9993" cy="102.667" r="1.66667" transform="rotate(180 31.9993 102.667)" fill="#ffffff" />
            <circle cx="75.6654" cy={88} r="1.66667" transform="rotate(180 75.6654 88)" fill="#ffffff" />
            <circle cx="31.9993" cy={88} r="1.66667" transform="rotate(180 31.9993 88)" fill="#ffffff" />
            <circle cx="75.6654" cy="73.3333" r="1.66667" transform="rotate(180 75.6654 73.3333)" fill="#ffffff" />
            <circle cx="31.9993" cy="73.3333" r="1.66667" transform="rotate(180 31.9993 73.3333)" fill="#ffffff" />
            <circle cx="75.6654" cy={45} r="1.66667" transform="rotate(180 75.6654 45)" fill="#ffffff" />
            <circle cx="31.9993" cy={45} r="1.66667" transform="rotate(180 31.9993 45)" fill="#ffffff" />
            <circle cx="75.6654" cy={16} r="1.66667" transform="rotate(180 75.6654 16)" fill="#ffffff" />
            <circle cx="31.9993" cy={16} r="1.66667" transform="rotate(180 31.9993 16)" fill="#ffffff" />
            <circle cx="75.6654" cy={59} r="1.66667" transform="rotate(180 75.6654 59)" fill="#ffffff" />
            <circle cx="31.9993" cy={59} r="1.66667" transform="rotate(180 31.9993 59)" fill="#ffffff" />
            <circle cx="75.6654" cy="30.6666" r="1.66667" transform="rotate(180 75.6654 30.6666)" fill="#ffffff" />
            <circle cx="31.9993" cy="30.6666" r="1.66667" transform="rotate(180 31.9993 30.6666)" fill="#ffffff" />
            <circle cx="75.6654" cy="1.66665" r="1.66667" transform="rotate(180 75.6654 1.66665)" fill="#ffffff" />
            <circle cx="31.9993" cy="1.66665" r="1.66667" transform="rotate(180 31.9993 1.66665)" fill="#ffffff" />
            <circle cx="60.9993" cy={132} r="1.66667" transform="rotate(180 60.9993 132)" fill="#ffffff" />
            <circle cx="17.3333" cy={132} r="1.66667" transform="rotate(180 17.3333 132)" fill="#ffffff" />
            <circle cx="60.9993" cy="117.333" r="1.66667" transform="rotate(180 60.9993 117.333)" fill="#ffffff" />
            <circle cx="17.3333" cy="117.333" r="1.66667" transform="rotate(180 17.3333 117.333)" fill="#ffffff" />
            <circle cx="60.9993" cy="102.667" r="1.66667" transform="rotate(180 60.9993 102.667)" fill="#ffffff" />
            <circle cx="17.3333" cy="102.667" r="1.66667" transform="rotate(180 17.3333 102.667)" fill="#ffffff" />
            <circle cx="60.9993" cy={88} r="1.66667" transform="rotate(180 60.9993 88)" fill="#ffffff" />
            <circle cx="17.3333" cy={88} r="1.66667" transform="rotate(180 17.3333 88)" fill="#ffffff" />
            <circle cx="60.9993" cy="73.3333" r="1.66667" transform="rotate(180 60.9993 73.3333)" fill="#ffffff" />
            <circle cx="17.3333" cy="73.3333" r="1.66667" transform="rotate(180 17.3333 73.3333)" fill="#ffffff" />
            <circle cx="60.9993" cy={45} r="1.66667" transform="rotate(180 60.9993 45)" fill="#ffffff" />
            <circle cx="17.3333" cy={45} r="1.66667" transform="rotate(180 17.3333 45)" fill="#ffffff" />
            <circle cx="60.9993" cy={16} r="1.66667" transform="rotate(180 60.9993 16)" fill="#ffffff" />
            <circle cx="17.3333" cy={16} r="1.66667" transform="rotate(180 17.3333 16)" fill="#ffffff" />
            <circle cx="60.9993" cy={59} r="1.66667" transform="rotate(180 60.9993 59)" fill="#ffffff" />
            <circle cx="17.3333" cy={59} r="1.66667" transform="rotate(180 17.3333 59)" fill="#ffffff" />
            <circle cx="60.9993" cy="30.6666" r="1.66667" transform="rotate(180 60.9993 30.6666)" fill="#ffffff" />
            <circle cx="17.3333" cy="30.6666" r="1.66667" transform="rotate(180 17.3333 30.6666)" fill="#ffffff" />
            <circle cx="60.9993" cy="1.66665" r="1.66667" transform="rotate(180 60.9993 1.66665)" fill="#ffffff" />
            <circle cx="17.3333" cy="1.66665" r="1.66667" transform="rotate(180 17.3333 1.66665)" fill="#ffffff" />
            <circle cx="46.3333" cy={132} r="1.66667" transform="rotate(180 46.3333 132)" fill="#ffffff" />
            <circle cx="2.66536" cy={132} r="1.66667" transform="rotate(180 2.66536 132)" fill="#ffffff" />
            <circle cx="46.3333" cy="117.333" r="1.66667" transform="rotate(180 46.3333 117.333)" fill="#ffffff" />
            <circle cx="2.66536" cy="117.333" r="1.66667" transform="rotate(180 2.66536 117.333)" fill="#ffffff" />
            <circle cx="46.3333" cy="102.667" r="1.66667" transform="rotate(180 46.3333 102.667)" fill="#ffffff" />
            <circle cx="2.66536" cy="102.667" r="1.66667" transform="rotate(180 2.66536 102.667)" fill="#ffffff" />
            <circle cx="46.3333" cy={88} r="1.66667" transform="rotate(180 46.3333 88)" fill="#ffffff" />
            <circle cx="2.66536" cy={88} r="1.66667" transform="rotate(180 2.66536 88)" fill="#ffffff" />
            <circle cx="46.3333" cy="73.3333" r="1.66667" transform="rotate(180 46.3333 73.3333)" fill="#ffffff" />
            <circle cx="2.66536" cy="73.3333" r="1.66667" transform="rotate(180 2.66536 73.3333)" fill="#ffffff" />
            <circle cx="46.3333" cy={45} r="1.66667" transform="rotate(180 46.3333 45)" fill="#ffffff" />
            <circle cx="2.66536" cy={45} r="1.66667" transform="rotate(180 2.66536 45)" fill="#ffffff" />
            <circle cx="46.3333" cy={16} r="1.66667" transform="rotate(180 46.3333 16)" fill="#ffffff" />
            <circle cx="2.66536" cy={16} r="1.66667" transform="rotate(180 2.66536 16)" fill="#ffffff" />
            <circle cx="46.3333" cy={59} r="1.66667" transform="rotate(180 46.3333 59)" fill="#ffffff" />
            <circle cx="2.66536" cy={59} r="1.66667" transform="rotate(180 2.66536 59)" fill="#ffffff" />
            <circle cx="46.3333" cy="30.6666" r="1.66667" transform="rotate(180 46.3333 30.6666)" fill="#ffffff" />
            <circle cx="2.66536" cy="30.6666" r="1.66667" transform="rotate(180 2.66536 30.6666)" fill="#ffffff" />
            <circle cx="46.3333" cy="1.66665" r="1.66667" transform="rotate(180 46.3333 1.66665)" fill="#ffffff" />
            <circle cx="2.66536" cy="1.66665" r="1.66667" transform="rotate(180 2.66536 1.66665)" fill="#ffffff" />
          </svg>
        </span>
      </div>
    </footer>
  )
}

export default Footer;