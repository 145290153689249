import { Navigate, Routes, Route } from 'react-router-dom';

import * as Pages from './pages';

import ScrollToTop from './utils/scrollToTop';

function App() {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Pages.ChatspaceLanding />} />
        <Route path="/privacy" element={<Pages.Privacy />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </ScrollToTop>
  );
}

export default App;
